<template>
  <b-overlay rounded="sm">
    <b-container fluid>
      <form @submit.prevent="handleSubmitOperation" autocomplete="off">
        <b-row class="mt-5">

          <b-col sm="4">
                 <span
                   class="text-danger">{{ hasError('decors_id') ? "Please select one record." : "" }}
                 </span>
            <b-form-group label="Select Packacge*">
              <treeselect
                @input="handleChangeDecors"
                @deselect="handleDeleteOperation"
                :class="((hasError('decors_id') ?  'error-input'  : null))"
                :multiple="false"
                :options="_.map(dropdowns.decors,(i)  => {
                    return {
                        id:i.decors_id,
                        label: i.decors_id === decor.decors_id && decor.id ? decor.label : i.label
                    }
                })"
                v-model="decor.decors_id" />
            </b-form-group>
            <b-form-group label="Rate*">
              <b-form-input
                :class="((hasError('rate') ?  'error-input'  : null))"
                @keyup="handleChangeInput"
                v-model="decor.rate"
                v-numericOnly.keyup></b-form-input>
            </b-form-group>

            <b-card-text>
              <table class="table table-bordered" id="discount-table">
                <tr>
                  <th>Total Amount</th>
                  <td>{{
                      $global.numberToLocationString(totalAmountS(
                        {
                          'decors': [decor]
                        }).amount)
                    }}
                  </td>
                </tr>
                <tr>
                  <th>
                    GST@{{
                      $global.numberToLocationString(totalAmountS(
                        {
                          'decors': [decor]
                        }).gst)
                    }}%
                  </th>
                  <td>
                    {{
                      $global.numberToLocationString(totalAmountS(
                        {
                          'decors': [decor]
                        }).gstAmount)
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Total Amount + GST</th>
                  <td>
                    {{
                      $global.numberToLocationString(totalAmountS(
                        {
                          'decors': [decor]
                        }).total)
                    }}
                  </td>
                </tr>
              </table>
            </b-card-text>
          </b-col>
          <b-col sm="8">
            <b-form-group label="Deliverables">
              <ckeditor
                :config="editorDefaultConfig"
                v-model="decor.deliverables"
                :disabled="!decor.decors_id">
              </ckeditor>
            </b-form-group>

          </b-col>


        </b-row><!--/b-row-->
        <b-row>
          <b-col cols="12">
            <vue-dropzone ref="dropzone" id="dropzone" :options="dropzoneOptions"
                          v-on:vdropzone-sending="sendingEvent"
                          v-on:vdropzone-removed-file="removeFile"
            ></vue-dropzone>
          </b-col>
        </b-row>
      </form><!--/form-->
    </b-container><!--/b-container-->
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex';
import { request } from "@/core/services/Request";
import { getPropertyId } from "@/util/globalFunction";
import handleMatchDataMixin from "../handleMatchDataMixing";
import { bus } from "@/main";
import totalAmountMixin from "../../../../../../assets/js/components/totalAmountMixin";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import JwtService from "@/core/services/jwt.service";
import handleError from "@/util/handleError";

export default {
  mixins: [handleMatchDataMixin, totalAmountMixin, handleError],
  props: ["event_id", "nkey"],
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    const {access_token} = JwtService.getToken();

    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/api/bookings/decors/upload_files`,
        headers: { 'Authorization': `Bearer ${access_token}` },
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        parallelUploads: 10,
      },
      removed_files: [],
      decor: {
        decors_id: null,
        deliverables: null,
        rate: null,
        gst: null,
        label: null,
      },
      dropdowns: {
        decors: [],
      },
      loading: true,
      editorDefaultConfig: {
        height: '323px',
        extraPlugins: ['colorbutton,justify'],
        toolbarGroups: [
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
          }
        ]
      },
    };
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("id", this.decors_id);
    },
    removeFile(file) {
      if (file.id) {

        this.removed_files.push(file.id)
      }
    },
    handleChangeDecors(val) {

      if (val && this.dropdowns.decors) {

        const decor = this.dropdowns.decors.filter(decor => {
          return decor.decors_id === val
        })

        if (decor) {

          this.decor = {...decor[0]}
        }
      } else {

        this.decor.rate = null;
        this.decor.gst = null;
        this.decor.deliverables = null;
        this.decor.decors_id = null;
        this.decor.label = null;

        this.removed_files = []
      }

      this.handleChangeInput()
    },
    async handleDeleteOperation() {

      if (this.decor) {

        const response = await request({
          method: 'delete',
          url: `/quotations/events/decors/delete/${ this.decor.id }`,
        });
      }
    },
    async handleEditOperation(id) {
      try {

        const response = await request({
          method: 'get',
          url: `/quotations/events/decors/detail/${ id }`,
        });
        const { data } = response.data;
        this.loading = false;

        if ( data ) {

          this.decor = data

          if (data.media) {

            const dropzone = this.$refs.dropzone;
            data.media.map(i => {
              dropzone.manuallyAddFile(i, i.url);
            })
          }
          this.dropdowns.decors = this.dropdowns.decors.map(decor => {

            if (decor.decors_id === data.decors_id) {
              return data
            } else {
              data.label = data.name
              return decor
            }
          })

          this.oldData = _.cloneDeep({
            decor: this.decor,
          });
        }
      } catch (e) {
        console.error(e)
        this.$global.itemEditFails();

        this.decor = {
          decors_id: null,
          deliverables: null,
          rate: null,
          gst: null,
          label: null,
        };

        this.removed_files = []

      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: '/quotations/events/decors/create',
          method: 'post',
          data: {
            decor: this.decor,
            removed_files: this.removed_files,
            quotation_event_id: this.event_id
          },
        });

        const { data } = response.data;

        if (data.id) {

          this.decors_id  = data.id
          await this.$refs.dropzone.processQueue()
          this.$store.dispatch("removeDataMatchRequests");

        }

        this.$global.notify("Decor saved successfully", "success");

      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async getExtras() {
      try {
        const response = await request({
          url: `/dropdowns/decors`,
          method: 'post',
        });

        const { data } = response.data;

        this.dropdowns.decors = _.map(data, (i) => {
          return {
            'id': i.id,
            'label': i.name,
            'gst': parseFloat(i.s_gst) + parseFloat(i.c_gst),
            'rate': i.rate,
            'deliverables': i.deliverables,
            'decors_id': i.id,
          };
        });
      } catch (e) {
        console.error(e)
      }
    },
    handleChangeInput() {

      const changeData = {
        decor: this.decor,
      };


      bus.$emit('decors', [this.decor], {}, this.nkey);

      this.$emit('update', 'decors', !_.isEqual(this.oldData, changeData));
    },
  },

  async mounted() {
    this.formErrors = new Error({});
    await this.getExtras();
    this.$nextTick(async function () {
      await this.handleEditOperation(this.event_id);
    })
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>


<style scoped>
table#discount-table {
  width: 100%;
}


.file-selector {
  padding: 55px;
  font-weight: 600;
  background-color: #f9f9f9;
  color: #4e5b69;
  z-index: -9;
}
figure {
  margin: 0px;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed #ccc;
  border-radius: 15px;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
button {
  background: #031629;
  box-shadow: 0 0 2px 0 rgba(3, 22, 41, 0.11),
  0 6px 16px 0 rgba(3, 22, 41, 0.08);
  font-family: SFProDisplay-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.4px;
  padding: 12px 30px;
  border-radius: 4px;
}
.separator {
  position: relative;
}
.separator:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 200px;
  background: #d8d8d8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
span {
  position: relative;
  background: #f9f9f9;
  padding: 0 4px;
  z-index: 9;
  font-size: 12px;
  color: #4e5b69;
}
</style>
