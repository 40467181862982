<template>
  <div>
    <div class="text-right mb-2">
      <p>
        <PDisplayText size="small"><strong>Due Amount</strong>:
          {{ $global.numberToLocationString(total_due_amount) }}
        </PDisplayText>
      </p>
    </div>
    <div v-if="formFields">
      <div :key="key" class="RoomSection" v-for="(form,key) in formFields">
        <PLayout class="mt-3">
          <PLayoutSection one-half>
            <PCard sectioned>
              <PFormLayout>
                <PFormLayoutGroup condensed>
                  <PSelect
                    :disabled="!form.mode"
                    :error="getError(`financials.${key}.mode_of_payment`)"
                    :id="`mode_of_payment_${key}`"
                    :multiple="false"
                    :options="dropdowns.mode_of_payment"
                    @input="handleChange($event,'mode_of_payment',key)"
                    label="Mode *"
                    placeholder="Mode"
                    v-model="form.mode_of_payment"
                    value-field="id"
                  />

                  <PTextField :disabled="!form.mode" :error="getError(`financials.${key}.transaction_id`)"
                              :id="`transaction_id_${key}`"
                              label="Transaction Id *"
                              min="0"
                              type="text"
                              v-if="!['cash','cheque', 'company'].includes(form.mode_of_payment)"
                              v-model="form.transaction_id"
                  ></PTextField>

                  <PConnected v-if="form.mode_of_payment === 'cheque'">
                    <PTextField :disabled="!form.mode"
                                :error="getError(`financials.${key}.cheque_number`)"
                                :id="`cheque_number_${key}`"
                                label="Cheque No*"
                                min="0"
                                placeholder="Cheque" slot="left"
                                type="number"
                                v-model="form.cheque_number"
                                v-numericOnly.keyup
                    >
                    </PTextField>

                    <PTextField :disabled="!form.mode"
                                :error="getError(`financials.${key}.cheque_bank`)"
                                :id="`cheque_bank_${key}`"
                                label="Cheque Bank *"
                                min="0"
                                placeholder="Bank"
                                v-model="form.cheque_bank"
                    ></PTextField>
                  </PConnected>
                </PFormLayoutGroup>
                <PFormLayoutGroup condensed>
                  <PDatePicker :date-range="getDate(form.cheque_date)"
                               :disabled="!form.mode"
                               :error="getError(`financials.${key}.cheque_date`)"
                               :id="`cheque_date_${key}`"
                               :label="`Cheque Date`+ (form.mode_of_payment !== 'cheque' ? `` : `  *`)"
                               :single-date-picker="true"
                               @change="handleChange($event.startDate,'cheque_date',key)"
                               auto-apply
                               format="DD/MM/YYYY"
                               v-if="form.mode_of_payment === 'cheque'"
                               v-model='form.cheque_date'
                  />

                  <PDatePicker :date-range="getDate(form.date_of_payment)"
                               :disabled="!form.mode"
                               :error="getError(`financials.${key}.date_of_payment`)"
                               :id="`date_of_payment_${key}`"
                               :single-date-picker="true"
                               @change="handleChange($event.startDate,'date_of_payment',key)"
                               auto-apply
                               format="DD/MM/YYYY"
                               label="Date Of Payment *"
                               v-model='form.date_of_payment'
                               v-if="form.mode_of_payment !== 'company'"
                  />
                </PFormLayoutGroup>
                <PFormLayoutGroup condensed>
                  <PTextField :disabled="!form.mode"
                              :error="getError(`financials.${key}.paid_amount`)"
                              :id="`paid_amount_${key}`"
                              @input="handleChangePaidAmount($event,key)"
                              label="Paid Amount *"
                              min="0"
                              v-if="form.mode_of_payment !== 'company'"
                              v-model="form.paid_amount"
                              v-numericOnly.keyup
                  ></PTextField>

                  <PDatePicker :date-range="getDate(form.due_date)"
                               :disabled="!form.mode || total_due_amount === 0"
                               :error="getError(`financials.${key}.due_date`)"
                               :id="`due_date_${key}`"
                               :single-date-picker="true"
                               v-if="form.mode_of_payment !== 'company'"
                               @change="handleChange($event.startDate,'due_date',key)"
                               auto-apply
                               format="DD/MM/YYYY"
                               label="Next Payment Date"
                               v-model='form.due_date'
                  />
                  <!--                                <PTextField :disabled="true" :id="`due_amount_${key}`" v-model="form.due_amount" min="0"-->
                  <!--                                            v-numericOnly.keyup-->
                  <!--                                            :error="getError(`financials.${key}.due_amount`)"-->
                  <!--                                            label="Due Amount" type="text"></PTextField>-->
                </PFormLayoutGroup>
                <PFormLayoutGroup>
                  <PTextField
                    :disabled="!form.mode"
                    :error="getError(`rooms.${key}.notes`)"
                    :id="`notes_${key}`"
                    :minHeight="100" label="Note" multiline
                    v-model="form.notes"/>
                </PFormLayoutGroup>
                <PStack>
                  <PStackItem fill>
                    <PButtonGroup>
                      <PButton :disabled="global.pendingrequests > 0"
                               @click="handleSubmitOperation(key)"
                               primary
                               v-if="form.mode">
                        <i class="fa fa-spin fa-spinner"
                           v-show="global.pendingRequests > 0"></i>
                        Save
                      </PButton>
                      <PButton @click="handleEditForm(key)" primary v-else>
                        Edit
                      </PButton>
                    </PButtonGroup>
                  </PStackItem>
                  <PStackItem>
                    <PStackItem>
                      <PButton @click="handleDeleteOperation(form.id,key)" destructive>Delete
                      </PButton>
                    </PStackItem>
                  </PStackItem>
                </PStack>
              </PFormLayout>
            </PCard>
          </PLayoutSection>
          <PLayoutSection one-half>
            <PCard sectioned v-if="!key">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <th>SI No.</th>
                    <th>Mode</th>
                    <th>Paid Amount</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(data,index) in formFields">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ data.mode_of_payment === 'company' ? 'Bill to Company' : data.mode_of_payment }}
                      </td>
                      <td>
                        {{ $global.numberToLocationString(data.paid_amount) }}
                      </td>
                      <td class="text-center">
                        <i class="flaticon-download mr-5 text-primary cursor-pointer"
                           @click="downloadPaymentPdf(data.id)"></i>
                        <i class="flaticon-whatsapp cursor-pointer" @click="sendPaymentPdf(data.id)"></i>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </PCard>
          </PLayoutSection>
        </PLayout>
      </div>
    </div>

    <PStack alignment="center">
      <PStackItem fill>
        <PButtonGroup>
          <PButton :disabled="total_due_amount === 0 || (formFields && formFields[0].mode_of_payment === 'company')" @click="addFinancialDetail" class="mt-5"
                   primary>
            Add more
          </PButton>
        </PButtonGroup>
      </PStackItem>
    </PStack>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { request } from "@/core/services/Request";
import { bus } from "@/main";
import handleError from "@/util/handleError";
import totalAmountMixin from "../../../../assets/js/components/totalAmountMixin";

const DEFAULT_FORM_STATE = {
  mode_of_payment: null,
  date_of_payment: null,
  cheque_number: null,
  cheque_date: null,
  cheque_bank: null,
  transaction_id: null,
  paid_amount: null,
  notes: null,
  due_amount: null,
  due_date: null,
  mode: true,
};

export default {
  props: ["total", "id"],
  mixins: [handleError, totalAmountMixin],
  data() {
    return {
      total_due_amount: 0,
      formFields: [],
      details: [],
      dropdowns: {
        mode_of_payment: [
          { id: "cash", label: "Cash" },
          { id: "company", label: "Bill To Company" },
          { id: "cheque", label: "Cheque" },
          { id: "card", label: "Debit/Credit Card" },
          { id: "upi", label: "UPI" },
          { id: "neft", label: "NEFT" },
          { id: "IMPS", label: "imps" },
        ]
      },
      dueAmount: 0,
    };
  },
  methods: {
    async downloadPaymentPdf(id) {
      try {
        const response = await request({
          method: 'get',
          url: `bookings/receipt/download/pdf/${ id }`,
        });

        const { data } = response.data;

        if ( data ) {
          window.open(data.download_url, '_blank').focus();
        }

      } catch (e) {
      }
    },
    async sendPaymentPdf(id) {
      try {
        const response = await request({
          method: 'get',
          url: `bookings/receipt/send/pdf/${ id }/booking/${ this.id }`,
        });

        this.$global.sendQuotation();


      } catch (e) {
      }
    },
    handleChange(value, section, key = null, prop = null) {
      if ( key !== null ) {
        this.formFields[key][section] = value;
      } else {
        this.formFields[section] = value;
      }

    },
    handleChangePaidAmount(value, key = null) {
      if ( !value ) {
        this.total_due_amount = this.dueAmount;
      }

      let tPA = _.sum(_.map(_.filter(this.formFields, (f, index) => index !== key), (i) => parseFloat(i.paid_amount)));
      let includeCurrentValueToTotalPaidAmount = parseFloat(tPA) + parseFloat(value);

      this.total_due_amount = parseFloat(this.total) - parseFloat(includeCurrentValueToTotalPaidAmount);
      this.formFields[key]["due_amount"] = this.total_due_amount ? this.total_due_amount : null;

      // if (value >= this.dueAmount) {
      //     this.formFields[key].due_amount = 0
      //     this.formFields[key].paid_amount = ""
      //     this.formFields[key].paid_amount = this.dueAmount
      //     this.total_due_amount = 0
      // } else {
      //     if (this.formFields[key].due_amount) {
      //         let amount = this.dueAmount
      //         this.formFields[key].due_amount = ((parseFloat(amount - value) > 0) ? parseFloat(amount - value) : this.total_due_amount)
      //         this.total_due_amount = (parseFloat(amount - (_.sum(_.map(this.formFields, (i) => i.paid_amount)))) > 0) ? parseFloat(amount - (_.sum(_.map(this.formFields, (i) => i.paid_amount)))) : 0
      //     } else {
      //         this.formFields[key].due_amount = ((parseFloat(this.total - value) > 0) ? parseFloat(this.total - value) : this.total_due_amount)
      //         this.total_due_amount = (parseFloat(this.total - (_.sum(_.map(this.formFields, (i) => i.paid_amount)))) > 0) ? parseFloat(this.total - (_.sum(_.map(this.formFields, (i) => i.paid_amount)))) : 0
      //     }
      // }
    },
    getDate(value) {
      if ( value ) {
        return {
          startDate: value,
          endDate: value
        };
      }
    },
    addFinancialDetail() {
      this.errors = [];
      this.formFields.push({ ...DEFAULT_FORM_STATE });
    },
    async handleSubmitOperation(key = 0) {
      this.errors = [];

      try {
        const response = await request({
          url: this.formFields.id ? '/bookings/financial/update' : '/bookings/financial/create',
          method: 'post',
          data: {
            financials: { [`${ key }`]: this.formFields[key] },
            booking_id: this.$route.params.id,
            total_due_amount: this.total_due_amount
          },
        });
        const { data } = response.data;
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }

        this.formFields = { ...DEFAULT_FORM_STATE };
        this.$bvModal.hide("add-payment");
        if ( this.$route.params.id ) {
          this.handleDetailOperation(this.$route.params.id);
        }
        bus.$emit("quotation-data-refresh");

        this.$store.dispatch("checkPendingRoomData", 0);
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.errors = JSON.parse(error.request.responseText).errors;
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async handleDetailOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/bookings/financial/detail/${ id }`,
        });
        const { data } = response.data;

        if ( data.length ) {
          this.total_due_amount = parseFloat(this.total - ( _.sum(_.map(data, (i) => parseFloat(i.paid_amount))) ));
          this.dueAmount = parseFloat(this.total - ( _.sum(_.map(data, (i) => parseFloat(i.paid_amount))) ));
          this.formFields = data;

        } else {
          this.formFields.push({ ...DEFAULT_FORM_STATE });
          this.formFields.map((i) => {
            i.due_amount = this.total;
            return i;
          });
          this.total_due_amount = this.dueAmount = this.total;
        }
      } catch (e) {
        this.$global.itemEditFails();
      }
    },
    async handleEditForm(key) {
      this.formFields[key].mode = true;
      this.$store.dispatch("checkPendingRoomData", 1);
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/bookings/financial/edit/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data
        };
        this.$bvModal.show("add-payment");
        // this.formFields.due_amount = this.dueAmount
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async handleDeleteOperation(id, key) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {

        if ( key !== -1 ) {
          if ( this.formFields[key].paid_amount > 0 ) {
            this.total_due_amount = parseFloat(this.total_due_amount) + parseFloat(this.formFields[key].paid_amount);
            this.dueAmount = this.total_due_amount;
          }
          if ( this.formFields[key].id ) {
            try {
              const response = await request({
                method: 'post',
                url: `/bookings/financial/delete`,
                data: {
                  id: id,
                }
              });
              if ( this.$route.query.id ) {
                this.handleDetailOperation(this.$route.query.id);
              }
              bus.$emit("quotation-data-refresh");

            } catch (e) {
              this.$global.itemDeleted();
              this.formFields = { ...DEFAULT_FORM_STATE };
            }
          }

          this.$delete(this.formFields, key);
        }
      }
    },
    dueAmountCalculation(data) {
      let total = 0;
      let mainTotal = this.dueAmount = this.total;
      _.map(data, (i) => {
        total += i.paid_amount;
      });
      this.dueAmount = parseFloat(mainTotal - total);
      this.formFields.due_amount = this.dueAmount;
      return this.dueAmount;
    },
    // changeInput() {
    //     // this.dueAmountCalculation(this.details)
    //
    //     if (this.dueAmount < 0) {
    //         this.formFields.due_amount = 0
    //         this.formFields.paid_amount = this.dueAmount
    //     } else if (parseInt(this.dueAmount) < parseInt(this.formFields.paid_amount)) {
    //         var alertString = 'only ' + this.dueAmount + ' Rs. you need';
    //         alert(alertString)
    //         this.formFields.paid_amount = this.dueAmount
    //         this.formFields.due_amount = 0
    //     } else if (this.dueAmount >= this.formFields.paid_amount) {
    //         this.formFields.due_amount = parseFloat(this.dueAmount - this.formFields.paid_amount)
    //     } else {
    //         this.formFields.due_amount = this.dueAmount
    //     }
    // },
  },
  mounted() {
    if ( this.$route.params.id ) {
      this.handleDetailOperation(this.$route.params.id);
    }
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>

<style scoped>
table th {
  white-space: nowrap;
  width: 10%;
}

.flaticon-whatsapp {
  color: #25D366;
}
</style>
