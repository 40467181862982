<template>
  <div>
    <b-tabs lazy>
      <b-tab title="Set F&B" @click="handleChangeTab('fnb',tab)"
             :active="tab === 'fnb'">
        <set-fnb @update="handleUpdate" ref="fnb" :fnb="fnb" :event_id="event_id"
                 :nkey="nkey"
                 :quotation_id="quotation_id" :book="book"></set-fnb>
      </b-tab>
      <b-tab title="Set F&BExtras" @click="handleChangeTab('fnb_extras',tab)"
             :active="tab === 'fnb_extras'">
        <fnb-extra @update="handleUpdate" ref="fnb_extras" :extras="extras"
                   :event_id="event_id"
                   :nkey="nkey"
                   :quotation_id="quotation_id"></fnb-extra>
      </b-tab>
      <b-tab title="Set Decors" @click="handleChangeTab('decors',tab)"
             :active="tab === 'decors'">
        <decors @update="handleUpdate" ref="decors" :decors="decors" :ddiscount="ddiscount"
                :nkey="nkey"
                :event_id="event_id"
                :quotation_id="quotation_id"></decors>
      </b-tab>
      <b-tab title="Set Other Charges" @click="handleChangeTab('other_charges',tab)"
             :active="tab === 'other_charges'">
        <other-charges
          @update="handleUpdate" ref="other_charges"
          :other_charges="other_charges"
          :nkey="nkey"
          :event_id="event_id"
          :quotation_id="quotation_id" :ocdiscount="ocdiscount"></other-charges>
      </b-tab>
      <b-tab title="Set Office Note" @click="handleChangeTab('office_note',tab)"
             :active="tab === 'office_note'">
        <office-note :onote="onote"
                     @update="handleUpdate" ref="office_note"
                     :event_id="event_id"
                     :nkey="nkey"
                     :quotation_id="quotation_id"></office-note>
      </b-tab>
      <b-tab title="Set Guest Note" @click="handleChangeTab('guest_note',tab)"
             :active="tab === 'guest_note'">
        <guest-note :gnote="gnote"
                    @update="handleUpdate" ref="guest_note"
                    :event_id="event_id"
                    :nkey="nkey"
                    :quotation_id="quotation_id"></guest-note>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import setFnb from "../preferences/setFnb/index.vue";
import officeNote from "../preferences/officeNotes/index.vue";
import guestNote from "../preferences/guestNotes/index.vue";
import fnbExtra from "../preferences/fnbExtras/index.vue";
import decors from "../preferences/decors/index.vue";
import otherCharges from "../preferences/otherCharges/index.vue";
import { mapState } from 'vuex';
import { bus } from "../../../../../main";

export default {
  props: ["event_id", "quotation_id", "fnb", "onote", "gnote", "extras", "decors", "other_charges", 'ddiscount', 'ocdiscount', 'nkey', 'book'],
  components: {
    setFnb,
    officeNote,
    guestNote,
    fnbExtra,
    decors,
    otherCharges,
  },
  data() {
    return {
      tab: 'fnb',
      updates: {
        fnb: false,
        fnb_extras: false,
        decors: false,
        other_charges: false,
      }
    };
  },
  methods: {
    async savePreferences() {
      const tab = this.$refs[this.tab];
      this.updates[this.tab] = false;
      await tab.handleSubmitOperation();
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },
    async handleChangeTab(tab, previous_tab) {
      const refs = this.$refs[previous_tab];
      this.tab = tab;
      this.$emit('tab-change', tab)
      if ( previous_tab && this.updates[previous_tab] ) {
        this.updates[previous_tab] = false;
        let response = await this.$root.$confirm('Changes are not saved', `Previous changes are not saved, do you want to save the changes?`, {
          primaryBtnText: 'Save'
        });
        if ( response ) {
          refs.handleSubmitOperation();
        } else {
          this.refreshData();
        }
      }
    },
    handleUpdate(changed_item, changed_status) {
      if ( changed_status ) {
        this.updates[changed_item] = changed_status;
      }
    },
    refreshData() {
      this.$emit('refresh');
      bus.$emit('quotation-event-refresh');
    }
  },
  // beforeDestroy() {
  //   bus.$off('quotation-event-refresh');
  // },
  computed: {
    ...mapState([
      'global',
    ]),
  },

};
</script>
