<template>
  <div>
    <div v-show="dropdowns.rooms.length > 0">
      <div :key="key" class="RoomSection" v-for="(form,key) in formFields">
        <PLayout>
          <PLayoutSection one-half>
            <PCard sectioned>
              <PFormLayout>
                <PFormLayoutGroup condensed>
                  <PDatePicker :date-range="getDate(form.date)"
                               :disabled="!form.mode"
                               :error="getError(`rooms.${key}.date`)"
                               :id="`check_in_date_${key}`"
                               @change="handleChange($event,'date', key)"
                               auto-apply
                               :ranges="false"
                               format="DD/MM/YYYY"
                               label="Check In Date *"
                               v-model='form.date'
                  />

                  <PSelect
                    :disabled="!form.mode"
                    :error="getError(`rooms.${key}.room_id`)"
                    :id="`room_id_${key}`"
                    :multiple="false"
                    :options="dropdowns.rooms"
                    @input="handleChange($event,'room_id',key)"
                    label="Select Room Type *"
                    placeholder="Select Room Type"
                    v-model="form.room_id"
                    value-field="id"
                  />
                </PFormLayoutGroup>


                <PFormLayoutGroup condensed>
                  <PConnected>
                    <PTextField
                      :disabled="!form.mode"
                      :error="getError(`rooms.${key}.rate`)" :id="`rate_${key}`"
                      label="Rate *"
                      min="0"
                      slot="left"
                      type="number" v-model="form.rate" />

                    <PTextField
                      :disabled="!form.mode"
                      :error="getError(`rooms.${key}.gst`)" :id="`gst_${key}`"
                      label="GST *"
                      min="0"
                      type="number" v-model="form.gst" />

                    <PTextField
                      :disabled="!form.mode" :error="getError(`rooms.${key}.no_of_room`)"
                      :id="`no_of_room_${key}`"
                      label="No Of Room *"
                      slot="right"
                      type="number" v-model="form.no_of_room" />
                  </PConnected>

                </PFormLayoutGroup>
                <PFormLayoutGroup>
                  <PTextField
                    :disabled="!form.mode"
                    :error="getError(`rooms.${key}.remark`)"
                    :id="`remark_${key}`"
                    :minHeight="100" label="Remark" multiline
                    v-model="form.remark"/>
                </PFormLayoutGroup>
                <PStack>
                  <PStackItem fill>
                    <PButtonGroup>
                      <PButton :disabled="global.pendingrequests > 0"
                               @click="handleSubmitOperation(key)"
                               primary
                               v-if="form.mode">
                        <i class="fa fa-spin fa-spinner"
                           v-show="global.pendingRequests > 0"></i>
                        Save
                      </PButton>
                      <PButton @click="handleEditForm(key)" primary v-else>
                        Edit
                      </PButton>
                    </PButtonGroup>
                  </PStackItem>
                  <PStackItem>
                    <PButton :disabled="formFields.length == 1"
                             @click="handleDeleteOperation(form.id,key)"
                             destructive>Delete
                    </PButton>
                  </PStackItem>
                </PStack>
              </PFormLayout>
            </PCard>
          </PLayoutSection>
          <PLayoutSection one-half>
            <PCard sectioned v-if="!key">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                  <th>Check in</th>
                  <th>Rate</th>
                  <th>Rooms</th>
                  <th>Sub Total</th>
                  <th>GST</th>
                  <th>Gst Amount</th>
                  <th>Total</th>
                  </thead>
                  <tbody>
                  <tr :key="key" v-for="(room,key) in formFields">
                    <td>
                      {{ $global.dateFormat(room.check_in_date) }}
                    </td>
                    <td align="right">
                      {{ $global.numberToLocationString(room.rate) }}
                    </td>
                    <td>
                      {{ room.no_of_room }}
                    </td>
                    <td align="right">
                      {{ $global.numberToLocationString(totalRoomAmount(room).subTotal) }}
                    </td>
                    <td>
                      {{ $global.numberToLocationString(totalRoomAmount(room).gst) }}
                    </td>
                    <td align="right">
                      {{ $global.numberToLocationString(totalRoomAmount(room).gstAmount) }}
                    </td>
                    <td align="right">
                      {{ $global.numberToLocationString(totalRoomAmount(room).total) }}
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    <th> {{ totalRoomAmount(formFields).no_of_room }}</th>
                    <th></th>
                    <th></th>
                    <th class="text-right">{{
                        $global.numberToLocationString(totalRoomAmount(formFields).gstAmount)
                      }}
                    </th>
                    <th class="text-right">{{ $global.numberToLocationString(totalRoomAmount(formFields).amount) }}
                    </th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </PCard>
          </PLayoutSection>
        </PLayout>
      </div>

      <PButton @click="addRooms" class="mt" primary>Add Room</PButton>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {request} from "@/core/services/Request";
import {bus} from "@/main";
import totalAmountMixin from "../../../../assets/js/components/totalAmountMixin";
import handleError from "@/util/handleError";
import moment from "moment-timezone";
import {getPropertyId} from "@/util/globalFunction";

const FORM_STATE = {
  date: new Date(),
  gst: null,
  rate: null,
  remark: null,
  no_of_room: 0,
  room_id: null,
  mode: true,
};

export default {
  mixins: [totalAmountMixin, handleError],
  props: ["rooms", "quotation_id"],

  data() {
    return {
      formFields: [],
      detail: [],
      dropdowns: {
        rooms: [],
      },
      content_type: [
        "text",
        "numeric",
        "numeric",
        "numeric",
        "numeric"
      ],
      headings:
        [
          {
            "content": "GST%",
            "value": "gst",
            "type": "numeric",
            "sortable": false
          },
          {
            "content": "GST Amount",
            "value": "gst",
            "type": "numeric",
            "sortable": false
          },
          {
            "content": "Total",
            "value": "total",
            "type": "numeric",
            "sortable": false
          }
        ],
    };
  },
  mounted() {
    bus.$on('quotation-room-submit', () => {
      this.handleSubmitOperation();
    });

    this.getRooms();
    if (this.quotation_id) {
      this.getDetailOperation(this.quotation_id);
    } else {
      this.formFields.push(FORM_STATE);
    }
  },
  methods: {
    async handleEditForm(key) {
      this.formFields[key].mode = true;
      this.$store.dispatch("checkPendingRoomData", 1);
    },
    addRooms() {
      this.$store.dispatch("checkPendingRoomData", 1);
      this.formFields.push({...FORM_STATE});
    },
    getFormattedDate(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
    async getRooms() {
      try {
        const response = await request({
          url: `/dropdowns/rooms`,
          method: 'post',
        });

        const {data} = response.data;
        this.dropdowns.rooms = _.map(data, (i) => {
          return {
            id: i.id,
            label: i.title,
            gst: i.gst,
            rate: i.rate,
          };
        });
      } catch (e) {

      }
    },
    handleChangeRoomsDetail(key, id) {
      _.filter(this.dropdowns.rooms, (i) => {
        if (i.id == id) {
          this.formFields[key].rate = i.rate;
          this.formFields[key].gst = i.gst;
        }
      });
    },
    handleChange(value, section, key = null, prop = null) {

      if (key !== null) {
        if (section == 'room_id') {
          this.handleChangeRoomsDetail(key, value);
        }
        this.formFields[key][section] = value;
      } else {
        this.formFields[section] = value;
      }

    },
    getDate(value) {

      if (value) {
        return {
          startDate: value,
          endDate: value
        };
      }
    },

    async handleSubmitOperation(key) {
      this.errors = [];
      try {
        const response = await request({
          url: 'quotations/rooms/create',
          method: 'post',
          data: {rooms: {[key]: {...this.formFields[key]}}, quotation_id: this.quotation_id},
        });

        this.$global.itemUpdated("Room");
        bus.$emit("quotation-data-refresh");

        if (this.quotation_id) {
          this.getDetailOperation(this.quotation_id);
        }
        this.$store
          .dispatch("checkPendingRoomData", 0);

      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.errors = JSON.parse(error.request.responseText).errors;
          return false;
        }

        this.$global.handleRoomServerError(error);
      }
    },
    async handleDeleteOperation(id, key) {
      if (this.formFields.length > 1) {
        let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
          primaryBtnText: 'Yes',
          secondaryBtnText: 'No'
        });
        if (deleteResponse) {

          this.formFields.splice(key, 1);
          try {
            const response = await request({
              method: 'post',
              url: '/quotations/rooms/delete',
              data: {
                id: id,
              },
            });
            this.$global.itemDeleted();
            bus.$emit("quotation-data-refresh");
          } catch (errors) {
            this.$global.handleServerError(errors);
          }
        }
      }
    },
    async getDetailOperation(id) {
      this.errors = [];
      try {
        const response = await request({
          url: `quotations/rooms/detail/${id}`,
          method: 'get',
        });

        const {data} = response.data;

        if (data.length) {
          this.formFields = _.map(data, (item) => {
            item.room_id = item.allRooms ? item.allRooms.id : null;
            return item;
          });
        } else {
          this.formFields.push({...FORM_STATE});
        }
      } catch (error) {
        this.$global.handleServerError(error);
      }

    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.RoomSection {
  margin-bottom: 1.6rem;
}
</style>
