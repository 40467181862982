<template>
  <div>
    <PLayout :key="key" class="mb-4" v-for="(form,key) in formFields">
      <PLayoutSection one-half>
        <PCard class="mb-7" sectioned>
          <PFormLayout @submit.prevent="handleSubmitOperation"
                       autocomplete="off">
            <PFormLayoutGroup>
              <PConnected class="FunctionDate">
                <PDatePicker
                  :date-range="getDate(form.from_date)"
                  :disabled="!form.mode"
                  :error="getError(`events.${key}.from_date`)"
                  :id="`from_date_${key}`"
                  singleDatePicker
                  @change="handleChange($event.startDate,'from_date',key)"
                  auto-apply
                  format="DD/MM/YYYY"
                  label="From Date *"
                  slot="left"
                  v-model='form.from_date'
                />

                <PSelect
                  :disabled="!form.mode"
                  :error="getError(`events.${key}.from_time`)"
                  :id="`from_time_${key}`"
                  :multiple="false"
                  :options="dropdowns.fromTime"
                  @change="handleChange($event,'from_time',key)"
                  format="DD/MM/YYYY"
                  label="From Time *"
                  placeholder="From Time"
                  v-model="form.from_time"
                />

                <PSelect
                  :disabled="!form.mode"
                  :error="getError(`events.${key}.to_time`)"
                  :id="`to_time_${key}`"
                  :multiple="false"
                  :options="dropdowns.toTime"
                  @change="handleChange($event,'to_time',key)"
                  label="To Time *"
                  placeholder="To Time"
                  slot="right"
                  v-model="form.to_time"
                />
              </PConnected>
            </PFormLayoutGroup>

            <TreeSelect
              :disabled="!form.mode"
              :error="getError(`events.${key}.hall_id`)"
              :id="`hall_${key}`"
              :multiple="true"
              :options="_.map(dropdowns.halls,(i) => {
                                      return {
                                          id:i.id,
                                          label: (_.includes(form.hall_id,i.id) && form.id ? _.find(form.halls,{id:i.id} || {}).label : i.label),
                                          hall_slots: i.hall_slots,
                                      }
                                  })"
              @change="handleChange($event,'hall_id',key)"
              label="Select Halls *"
              placeholder="Select Halls"
              v-model="form.hall_id"
            />
            <PFormLayoutGroup condensed>
              <PSelect
                :disabled="!form.mode || formFields.hall_id"
                :error="getError(`events.${key}.slot`)"
                :id="`slot_${key}`"
                :multiple="false"
                :options="dropdowns.slots.length ? dropdowns.slots[key] || [] : []"
                @change="handleChange($event,'slot',key)"
                label="Select Slot *"
                placeholder="Select Slot"
                v-model="form.slot"
                value-field="id"
              />
              <PSelect
                :disabled="!form.mode"
                :error="getError(`events.${key}.event_type`)"
                :id="`event_type_${key}`"
                :multiple="false"
                :options="dropdowns.eventType"
                @change="handleChange($event,'event_type',key)"
                label="Event Type *"
                placeholder="Event Type"
                v-model="form.event_type"
                value-field="id"
              />
            </PFormLayoutGroup>

            <PSelect
              :disabled="!form.mode"
              :error="getError(`events.${key}.seating`)"
              :id="`seating_${key}`"
              :multiple="false"
              :options="dropdowns.seating"
              @change="handleChange($event,'seating', key)"
              label="Select seating *"
              placeholder="Select seating"
              v-model="form.seating"
              value-field="id"
            />
            <PStack>
              <PStackItem fill>
                <PButtonGroup>
                  <PButton :disabled="global.pendingrequests > 0" @click="handleSubmitOperation(key)"
                           primary
                           v-if="form.mode">
                    <i class="fa fa-spin fa-spinner"
                       v-show="global.pendingRequests > 0"></i>
                    Save
                  </PButton>
                  <PButton @click="handleEditForm(key)" primary v-else>
                    Edit
                  </PButton>
                </PButtonGroup>
              </PStackItem>
              <PStackItem>
                <PButtonGroup>
                  <PButton primary v-b-modal="`add-preferences-${key}`"
                           v-if="form.id">
                    Preferences
                  </PButton>
                  <PButton primary v-b-modal="`add-food-menu-${key}`"
                           v-if="(form.fnb && form.fnb.package_id)">
                    Menu
                  </PButton>
                  <PButton :disabled="formFields.length == 1"
                           @click="handleDeleteOperation(form.id,key)"
                           destructive>Delete
                  </PButton>
                </PButtonGroup>
              </PStackItem>
            </PStack>
          </PFormLayout>
        </PCard>
      </PLayoutSection>
      <PLayoutSection one-half>
        <PCard sectioned>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Rate</th>
                  <th>Pax</th>
                  <th>Amount</th>
                  <th>GST%</th>
                  <th>GST</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr class="mb-3" v-if="form.fnb && form.fnb !== undefined">
                  <td>F&B</td>
                  <td>
                    {{ $global.numberToLocationString(totalAmountS(form.fnb).rate) }}
                  </td>
                  <td>
                    {{ $global.numberToLocationString(totalAmountS(form.fnb).guest) }}
                  </td>
                  <td id="amount">
                    {{ $global.numberToLocationString(totalAmountS(form.fnb).amount) }}
                  </td>
                  <td>{{ totalAmountS(form.fnb).gst }}</td>
                  <td id="amount">
                    {{ $global.numberToLocationString(totalAmountS(form.fnb).gstAmount) }}
                  </td>
                  <td id="amount">
                    {{ $global.numberToLocationString(totalAmountS(form.fnb).total) }}
                  </td>
                </tr>
                <tr class="mb-3" v-else>
                  <td>F&B</td>
                  <td>0</td>
                  <td>0</td>
                  <td id="amount">
                    0
                  </td>
                  <td>0</td>
                  <td id="amount">
                    0
                  </td>
                  <td id="amount">
                    0
                  </td>
                </tr>
                <tr class="mb-3" v-if="form.fnbExtras">
                  <td>Fnb Extra</td>
                  <td>
                    {{
                      $global.numberToLocationString(totalAmountS({
                        'fnbExtras': form.fnbExtras,
                        'fnbExtrasDiscount': form.fnbExtrasDiscount
                      }).rate)
                    }}
                  </td>
                  <td>
                    {{
                      $global.numberToLocationString(totalAmountS({
                        'fnbExtras': form.fnbExtras,
                        'fnbExtrasDiscount': form.fnbExtrasDiscount
                      }).guest)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(totalAmountS({
                        'fnbExtras': form.fnbExtras,
                        'fnbExtrasDiscount': form.fnbExtrasDiscount
                      }).amount)
                    }}
                  </td>
                  <td>
                    {{
                      totalAmountS({
                        'fnbExtras': form.fnbExtras,
                        'fnbExtrasDiscount': form.fnbExtrasDiscount
                      }).gst
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(totalAmountS({
                        'fnbExtras': form.fnbExtras,
                        'fnbExtrasDiscount': form.fnbExtrasDiscount
                      }).gstAmount)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'fnbExtras': form.fnbExtras,
                          'fnbExtrasDiscount': form.fnbExtrasDiscount
                        }).total)
                    }}
                  </td>
                </tr>
                <tr class="mb-3" v-if="form.decors">
                  <td>Decors</td>
                  <td>
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'decors': form.decors,
                          'decorsDiscount': form.decorsDiscount
                        }).rate)
                    }}
                  </td>
                  <td>
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'decors': form.decors,
                          'decorsDiscount': form.decorsDiscount
                        }).guest)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'decors': form.decors,
                          'decorsDiscount': form.decorsDiscount
                        }).amount)
                    }}
                  </td>
                  <td>
                    {{
                      totalAmountS({
                        'decors': form.decors,
                        'decorsDiscount': form.decorsDiscount
                      }).gst
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'decors': form.decors,
                          'decorsDiscount': form.decorsDiscount
                        }).gstAmount)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'decors': form.decors,
                          'decorsDiscount': form.decorsDiscount
                        }).total)
                    }}
                  </td>
                </tr>
                <tr class="mb-3" v-if="form.otherCharges">
                  <td>Other Charges</td>
                  <td>
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'otherCharges': form.otherCharges,
                          'otherChargesDiscount': form.otherChargesDiscount
                        }).rate)
                    }}
                  </td>
                  <td>
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'otherCharges': form.otherCharges,
                          'otherChargesDiscount': form.otherChargesDiscount
                        }).guest)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'otherCharges': form.otherCharges,
                          'otherChargesDiscount': form.otherChargesDiscount
                        }).amount)
                    }}
                  </td>
                  <td>-</td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(totalAmountS({
                        'otherCharges': form.otherCharges,
                        'otherChargesDiscount': form.otherChargesDiscount
                      }).gstAmount)
                    }}
                  </td>
                  <td id="amount">
                    {{
                      $global.numberToLocationString(
                        totalAmountS({
                          'otherCharges': form.otherCharges,
                          'otherChargesDiscount': form.otherChargesDiscount
                        }).total)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td id="amount">
                    <strong>
                      {{
                        $global.numberToLocationString(totalAmountS(form.fnb).amount +
                          ( totalAmountS({
                            'fnbExtras': form.fnbExtras,
                            'fnbExtrasDiscount': form.fnbExtrasDiscount
                          }).amount )
                          + ( totalAmountS({
                            'decors': form.decors,
                            'decorsDiscount': form.decorsDiscount
                          }).amount )
                          + ( totalAmountS({
                            'otherCharges': form.otherCharges,
                            'otherChargesDiscount': form.otherChargesDiscount
                          }).amount ))
                      }}
                    </strong>
                  </td>
                  <td></td>
                  <td id="amount">
                    <strong>
                      {{
                        $global.numberToLocationString(totalAmountS(form.fnb).gstAmount +
                          ( totalAmountS({
                            'fnbExtras': form.fnbExtras,
                            'fnbExtrasDiscount': form.fnbExtrasDiscount
                          }).gstAmount )
                          + ( totalAmountS({
                            'decors': form.decors,
                            'decorsDiscount': form.decorsDiscount
                          }).gstAmount )
                          + ( totalAmountS({
                            'otherCharges': form.otherCharges,
                            'otherChargesDiscount': form.otherChargesDiscount
                          }).gstAmount ))
                      }}
                    </strong>
                  </td>
                  <td id="amount">
                    <strong>
                      {{
                        $global.numberToLocationString(parseFloat(totalAmountS(form.fnb).total) +
                          parseFloat(totalAmountS({
                            'fnbExtras': form.fnbExtras,
                            'fnbExtrasDiscount': form.fnbExtrasDiscount
                          }).total)
                          +
                          parseFloat(totalAmountS({
                            'decors': form.decors,
                            'decorsDiscount': form.decorsDiscount
                          }).total)
                          +
                          parseFloat(totalAmountS({
                            'otherCharges': form.otherCharges,
                            'otherChargesDiscount': form.otherChargesDiscount
                          }).total))
                      }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </PCard>
      </PLayoutSection>
      <b-modal :hide-footer="false" :id="`add-preferences-${key}`" @close="closeModalPreferences"
               no-close-on-backdrop
               no-close-on-esc
               scrollable size="xl"
               :title="`Preferences - ${event ? event.label : ''}`">

        <div>
          <preferences v-on="$listeners"
                       @tab-change="handleEventTabChange"
                       :book="book"
                       :decors="form.decors"
                       :event_id="form.id"
                       :extradiscount="form.fnbExtrasDiscount"
                       :extras="form.fnbExtras"
                       :fnb="form.fnb"
                       :gnote="form.guestNote"
                       :nkey="key"
                       :onote="form.officeNote"
                       :other_charges="form.otherCharges"
                       :quotation_id="qid"
                       ref="preferences"></preferences>
        </div>

        <template v-slot:modal-footer>
          <!-- Emulate built in modal footer ok and cancel button actions -->

          <span class="mr-5">
            <strong>Grand Total</strong> <span>&#8377;</span>
            {{ grand_total(form) }}
          </span>


          <b-button v-if="selected_tab === 'decors' && form.decors[0] && form.decors[0].media" @click="downloadCustomerPdf(qid,'decor','download')" variant="primary">
            <b-spinner label="Spinning" small v-if="preview_loading"></b-spinner>
            <span v-else> Preview </span>
          </b-button>


          <b-button @click="savePreferences(key)" variant="primary">
            <b-spinner label="Spinning" small v-if="loading"></b-spinner>
            <span v-else><i class="fa fa-save font-size-sm"></i> Save</span>
          </b-button>
        </template>
      </b-modal>

      <b-modal :id="`add-food-menu-${key}`"
               no-close-on-backdrop
               no-close-on-esc
               scrollable size="xxl"
               :title="foodMenuTitle(form)">

        <div>
          <FoodMenu ref="foodMenus" :event_id="form.id" :package_id="form.fnb ? form.fnb.package_id : null" />
        </div>


        <template v-slot:modal-footer>
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="downloadCustomerPdf(qid,'food-menu','download')" variant="primary">
            <span>
              <i class="fa fa-file font-size-sm"></i>
              Preview</span>
            <b-spinner class="ml-2" label="Spinning" small v-if="preview_loading"></b-spinner>

          </b-button>
        </template>
      </b-modal>
    </PLayout>
    <PButton @click="addEvents" class="mt" primary>Add Function</PButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { request } from "@/core/services/Request";
import Preferences from "../quotationEvents/preferences/index.vue";
import { bus } from "@/main";
import totalAmountMixin from "../../../../assets/js/components/totalAmountMixin";
import handleError from "@/util/handleError";
import PSelect from "../../../content/PSelect";
import FoodMenu from "@/view/pages/quotations/quotationEvents/preferences/setFnb/FoodMenu";


const DEFAULT_FORM_STATE = {
  from_time: null,
  to_time: null,
  from_date: null,
  to_date: null,
  hall_id: [],
  seating: null,
  overnight: null,
  slot: null,
  // hall_rent: null,
  event_type: null,
  // hall_gst: null,
  mode: true
};

export default {
  props: ["eid", "event", "qid", "book", "lead", "functions"],
  mixins: [totalAmountMixin, handleError],
  data() {
    return {
      formFields: [],
      selected_tab: '',
      loading: false,
      preview_loading: false,
      show_decor_preview: false,
      dropdowns: {
        rooms: [],
        seating: [],
        fromTime: [
          '00:00',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
        ],
        toTime: [
          '00:00',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
        ],
        halls: {
          hallByEvent: []
        },
        eventType: [],
        slots: [],
      },
    };
  },
  components: {
    Preferences,
    FoodMenu,
    TreeSelect: PSelect,
  },
  methods: {
    getDate(value) {
      if ( value ) {
        return {
          startDate: value,
          endDate: value
        };
      }
    },
    async downloadCustomerPdf(id, type, action) {
      try {
        this.preview_loading = true;
        const response = await request({
          method: 'get',
          url: `bookings/download/customer/pdf/${ id }/${ type }/${ action }`,
        });
        const { data } = response.data;
        if ( data ) {
          if ( action === "download" ) {
            window.open(data.download_url, '_blank');
          }
        }

      } catch (e) {
      }
      this.preview_loading = false;

    },
    addEvents() {
      this.$store
      .dispatch("checkPendingEventData", 1);
      this.formFields.push({ ...DEFAULT_FORM_STATE });
    },
    async getSeating() {
      try {
        const response = await request({
          url: `/dropdowns/seating`,
          method: 'post'
        });
        const {data} = response.data;

        const seating = data.map((item) => {

          return {
            id: item,
            label: item,
          };
        });

        seating.unshift({
          id: null,
          label: '-',
        });

        this.dropdowns.seating = seating;

      } catch (e) {
      }
    },
    async getRooms() {
      try {
        const response = await request({
          url: `/dropdowns/rooms`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.rooms = _.map(data, (i) => {
          return {
            id: i.id,
            label: i.title,
            gst: i.gst,
            rate: i.rate,
          };
        });
      } catch (e) {

      }
    },
    closeModalPreferences() {
      bus.$emit("quotation-data-refresh");
      this.$store.dispatch("checkPendingEventData", 0);

      // this.getDetailOperation(this.qid);

    },
    async handleEditForm(key) {
      await this.$store
        .dispatch("checkPendingEventData", 1);
      this.formFields[key].mode = true;
    },
    grand_total(form) {
      return this.$global.numberToLocationString(this.totalAmountS(form.fnb).total
        +
        ( this.totalAmountS({
          'fnbExtras': form.fnbExtras
        }).total )
        + ( this.totalAmountS({
          'decors': form.decors
        }).total )
        + ( this.totalAmountS({
          'otherCharges': form.otherCharges
        }).total ));
    },
    async handleSubmitOperation(key) {
      this.errors = [];

      try {
        const { data } = await request({
          url: '/quotations/events/create',
          method: 'post',
          data: { events: { [key]: { ...this.formFields[key] } }, quotation_id: this.qid },
        });

        await this.getDetailOperation(this.qid);

        this.$global.itemUpdated("Event");
        this.$store.dispatch("checkPendingEventData", 0);
      } catch
        (error) {
        console.error(error)
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.errors = JSON.parse(error.request.responseText).errors;
          return false;
        }
        this.$global.handleEventServerError(error);
      }
    },
    async getHalls() {
      try {
        const response = await request({
          url: `/dropdowns/halls`,
          method: 'post',
        });

        const { data } = response.data;

        this.dropdowns.halls = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
            hall_slots: item.slot,
          };
        });
      } catch (e) {

      }
    }
    ,
    async getEventType() {
      try {
        const response = await request({
          url: '/dropdowns/event/type',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.eventType = data.map((item) => {
          return {
            id: item,
            label: item,
          };
        });
      } catch (e) {

      }
    },
    async savePreferences() {
      this.loading = true;
      await this.$refs.preferences[0].savePreferences();
      // await this.getDetailOperation(this.qid);
      // bus.$emit("quotation-data-refresh");
      this.loading = false;
    },
    handleEventTabChange(tab) {

      this.selected_tab = tab;
    },
    async getDetailOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/quotations/events/detail/by/quotation/${ id }`,
        });
        const { data } = response.data;

        this.formFields = data;
        if ( !data.length && !this.formFields.length ) {
          this.formFields.push({ ...DEFAULT_FORM_STATE });
        }
      } catch (e) {
        console.error(e);
        this.$global.itemEditFails();
      } finally {
        this.formFields.map((i, key) => {
          this.handleChangeHalls(key);
        });
      }
    },
    async handleDeleteOperation(id, key) {
      if ( this.formFields.length > 1 ) {
        let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
          primaryBtnText: 'Yes',
          secondaryBtnText: 'No'
        });
        if ( deleteResponse ) {
          this.formFields.splice(key, 1);
          try {
            const response = await request({
              method: 'post',
              url: '/quotations/events/delete',
              data: {
                id: id,
              },
            });
            this.$global.itemDeleted();
          } catch (errors) {
            this.$global.handleServerError(errors);
          } finally {
            bus.$emit("quotation-data-refresh");
          }
        }
      }
    },
    handleChangeHalls(key) {
      let selectedHalls = ( _.filter(this.dropdowns.halls, (i) => _.includes(this.formFields[key].hall_id, i.id)) );
      let allSlots = [];
      selectedHalls.map(item => allSlots.push(...item.hall_slots));
      this.setSlotDropdownList(allSlots, this.formFields[key].hall_id.length, key);
    },
    setSlotDropdownList(slots, length, key) {
      let dropdown = ( length > 1 ) ? _.filter(slots, (val, i, iteratee) => _.includes(iteratee, val, i + 1)) : slots;
      this.dropdowns.slots[key] = _.map(dropdown, (i) => {
        return {
          id: i,
          label: i,
        };
      });

    },
    handleChange(value, section, key = null, prop = null) {
      if ( key !== null ) {
        if ( section === 'hall_id' ) {
          this.formFields[key][section] = value;
          this.handleChangeHalls(key);
        } else {
          this.formFields[key][section] = value;
        }
      } else {
        this.formFields[section] = value;
      }

    },
    foodMenuTitle(form) {
      if (form?.fnb) {
        return `${form.fnb ? form.fnb.package_label : ''} for ${form.event_type} on ${this.$global.dateFormat(form.from_date)} ${form.slot} slot for ${form.fnb.no_of_pax} guests / ${this.lead.title}`
      }
    }
  },
  async mounted() {

    await this.getHalls();

    bus.$on('quotation-event-refresh', async () => {
      await this.getDetailOperation(this.qid);
    });

    if ( this.functions ) {
      // this.getDetailOperation(this.qid);
      this.formFields = _.cloneDeep(this.functions)

      this.functions.map((fn, key) => {
        this.handleChangeHalls(key);
      })
    } else {
      this.formFields.length = 0;
      this.formFields.push(DEFAULT_FORM_STATE);
    }
    this.getEventType();
    this.getRooms();
    this.getSeating();

    bus.$on('decors', (data, discount, key) => {
      this.formFields[key].decors = data;
    });
    bus.$on('other-charges', (data, discount, key) => {
      this.formFields[key].otherCharges = data;

    });
    bus.$on('fnb-extras', (data, discount, key) => {
      this.formFields[key].fnbExtras = data;

    });
    bus.$on('fnb', (data, key) => {
      this.formFields[key].fnb = data;
    });
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
