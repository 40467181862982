<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container fluid>
      <form @submit.prevent="handleSubmitOperation" autocomplete="off">
        <b-row>
          <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
            <b-row>
              <div class="table-responsive mt-5">
                <span
                  class="text-danger">{{
                    ( formErrors.has('otherCharges') ) ? "Please select one record." : ""
                  }}</span>
                <table
                  class="v-data-table table table-head-custom table-vertical-center table-responsive table-responsive theme--light">
                  <thead>
                    <tr>
                      <th width="15%">Title</th>
                      <th width="10%">Type</th>
                      <th width="15%">Rate</th>
                      <th>No Of Pax</th>
                      <th class="text-center">Sub Total</th>
                      <th class="text-right">GST%</th>
                      <th class="text-right">GST</th>
                      <th class="text-right">Total</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>

                  <tbody :key="index" v-for="(extra,index) in formFields.otherCharges.length">
                    <tr>
                      <td width="20%">
                        <b-form-select
                          value-field="id"
                          text-field="label"
                          @change="handleSelectOtherCharge(index)"
                          :options="_.map(dropdowns.otherCharges,(i) =>{
                                                        return{id:i.id,label:i.particular}})"
                          v-model="formFields.otherCharges[index].other_charges_id"
                          :class="((formErrors.all()[`otherCharges.${index}.other_charges_id`] ?  'error-input'  : null))"
                        >
                        </b-form-select>
                      </td>
                      <td>
                        {{ formFields.otherCharges[index].type }}
                      </td>
                      <td>
                        <b-form-input
                          :class="((formErrors.all()[`otherCharges.${index}.rate`] ?  'error-input'  : null))"
                          @keyup="handleChangeInput"
                          v-model="formFields.otherCharges[index].rate"
                          v-numericOnly.keyup></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          :class="((formErrors.all()[`otherCharges.${index}.no_of_pax`] ?  'error-input'  : null))"
                          @keyup="handleChangeInput"
                          v-if="formFields.otherCharges[index].type !== 'Lumpsum'"
                          v-model="formFields.otherCharges[index].no_of_pax"
                          v-numericOnly.keyup></b-form-input>
                        <span v-else>
                          <b-form-input
                            :disabled="true"
                            :class="((formErrors.all()[`otherCharges.${index}.no_of_pax`] ?  'error-input'  : null))"
                            @keyup="handleChangeInput"
                            v-model="formFields.otherCharges[index].no_of_pax"
                            v-numericOnly.keyup></b-form-input>
                        </span>
                      </td>
                      <td class="text-center">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.otherCharges[index]).amount)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.otherCharges[index]).gst)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.otherCharges[index]).gstAmount)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.otherCharges[index]).total)
                        }}
                      </td>
                      <td class="text-center">
                        <i class="fa fa-trash" @click="removeOtherCharges(index)"></i>
                      </td>
                    </tr>
                  </tbody>
                  <b-col sm="12" class="mb-5">
                    <b-button variant="primary" @click="addMore">Add More</b-button>
                  </b-col>
                </table>
              </div>
            </b-row>
          </b-col><!--/b-col-->
        </b-row><!--/b-row-->
        <b-row>
          <b-col class="p-0" cols="4" offset="8">
            <b-card>
              <b-card-text>
                <table class="table table-bordered" id="discount-table">
                  <tr>
                    <th>Total Amount</th>
                    <td>
                      {{
                        $global.numberToLocationString(totalAmountS(formFields).amount)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      GST
                      {{
                        $global.numberToLocationString(totalAmountS(formFields).gst)
                      }}
                      %

                    </th>
                    <td>
                      {{
                        $global.numberToLocationString(totalAmountS(formFields).gstAmount)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Total Amount + GST</th>
                    <td>
                      {{
                        $global.numberToLocationString(totalAmountS(formFields).total)
                      }}

                    </td>
                  </tr>
                </table>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </form><!--/form-->
    </b-container><!--/b-container-->
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getPropertyId } from "@/util/globalFunction";
import handleMatchDataMixin from "../handleMatchDataMixing";
import { bus } from "../../../../../../main";
import totalAmountMixin from "../../../../../../assets/js/components/totalAmountMixin";

const DEFAULT_FORM_STATE = {
  otherCharges: [],
  discount_type: null,
  discount: null,
};

const SINGLE_DATA = {
  type: null,
  rate: 0,
  id: null,
  no_of_pax: 0,
  gst: 0,
  gstAmount: 0,
  sub_total: 0,
  total: 0,

};

export default {
  mixins: [handleMatchDataMixin, totalAmountMixin],
  props: ["otherCharges", "event_id", "ocdiscount", "nkey"],
  data() {
    return {
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      dropdowns: {
        otherCharges: [],
      },
      loading: true,
      other_charge: { ...SINGLE_DATA }
    };
  },
  methods: {
    removeOtherCharges(index) {
      this.formFields.otherCharges.splice(index, 1);
      this.handleChangeInput();
    },
    async handleSelectOtherCharge(index) {

      this.formErrors = new Error({});

      const data = this.formFields.otherCharges[index];
      const othersIndex = _.findIndex(this.dropdowns.otherCharges, (i) => i.id == data.other_charges_id);

      if ( othersIndex !== -1 ) {
        const otherCharge = this.dropdowns.otherCharges[othersIndex];

        this.formFields.otherCharges[index].gst = parseFloat(otherCharge.s_gst) + parseFloat(otherCharge.c_gst);
        this.formFields.otherCharges[index].rate = otherCharge.rate;
        this.formFields.otherCharges[index].type = otherCharge.type;
        this.formFields.otherCharges[index].no_of_pax = ( otherCharge.type == 'Lumpsum' ) ? 1 : 0;

        const changeTotal = this.totalAmountS(
          {
            'otherCharges': [this.formFields.otherCharges[index]],
            'discount_type': this.formFields.discount_type, 'discount':
            this.formFields.discount
          });


        this.formFields.otherCharges[index].sub_total = this.$global.numberToLocationString(changeTotal.amount);
        this.formFields.otherCharges[index].gstAmount = this.$global.numberToLocationString(changeTotal.gstAmount);

        this.handleChangeInput();
      }
    },
    addMore() {
      this.formFields.otherCharges.push({
        no_of_pax: null,
        type: null,
        other_charges_id: null,
        gst: null,
        rate: null,
      });

      this.handleChangeInput();
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/quotations/events/other/charges/detail/${ id }`,
        });
        const { data } = response.data;
        this.loading = false;
        if ( data ) {
          let self = this;

          _.map(data, (item, index) => {
            self.formFields.otherCharges.push({
              no_of_pax: item.no_of_pax,
              type: item.type_label,
              other_charges_id: item.other_charges_id,
              gst: item.gst,
              rate: item.rate,
            });
          });

          this.oldData = _.cloneDeep(this.formFields);
        }
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: '/quotations/events/other/charges/create',
          method: 'post',
          data: {
            otherCharges: this.formFields.otherCharges,
            quotation_event_id: this.event_id,
            discount: this.formFields.discount,
            discount_type: this.formFields.discount_type,
          },
        });
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.removeHandler();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async getOtherCharges() {
      this.formFields.otherCharges = [];
      try {
        const response = await request({
          url: `/dropdowns/other/charges`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.otherCharges = data;
      } catch (e) {

      }
    },
    handleChangeInput() {
      if ( !this.formFields.discount_type ) {
        this.formFields.discount = null;
      }

      this.$emit('update', 'other_charges', !_.isEqual(this.oldData, this.formFields));

      if ( this.totalAmountS(this.formFields).amount < 0 || ( this.formFields.discount_type == 'percentage' && this.formFields.discount > 100 ) ) {
        if ( this.formFields.discount_type == 'fixed' ) {
          this.formFields.discount = this.totalAmountS(this.oldData).amount;
        } else {
          this.formFields.discount = 100;
        }
      } else {
        bus.$emit('other-charges', this.formFields.otherCharges, {
          'discount_type': this.formFields.discount_type,
          'discount': this.formFields.discount
        }, this.nkey);
      }
    },
  },
  mounted() {
    this.formFields.otherCharges = [];
    this.formErrors = new Error({});
    this.getOtherCharges();
    this.handleEditOperation(this.event_id);
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
