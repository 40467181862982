<template>
  <div>
    <PPage
      :breadcrumbs="[{content: 'Customers', onAction: handleCancel}]"
      title="Manage Booking">

      <b-input-group-append slot="primaryAction">
        <b-dropdown id="dropdown-1" variant="light-primary" class="text-white font-weight-bolder">

          <template v-slot:button-content>
            <i class="ki ki-bold-menu"></i> Preview
          </template>
          <b-dropdown-item @click="downloadPdf(formFields.id)">Office copy</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'office-food-menu')">Office copy - Food menu</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'office-food-menu', 'gu')">Office copy - GJ Food menu</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'summary')">Summary</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'with_prices')">With Breakup</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'without_prices')">Without Breakup</b-dropdown-item>
          <b-dropdown-item @click="downloadPdf(formFields.id, 'food-menu')">Food menu</b-dropdown-item>
        </b-dropdown>
      </b-input-group-append>

      <PLayout>

        <PLayoutAnnotatedSection title="Customer Details">
          <PCard sectioned>
            <PFormLayout autocomplete="off">
              <PFormLayoutGroup condensed>
                <PMultiSelect
                  :disabled="true"
                  :error="getError('lead_id')"
                  :multiple="false"
                  :options="dropdowns.leads"
                  @input="handleEventChange();resetError('lead_id')"
                  id="lead_id"
                  label="Select Lead for Quotation"
                  textField="label"
                  v-model="lead"
                  valueField="id"
                >

                </PMultiSelect>

                <PMultiSelect
                  :disabled="true"
                  :error="getError('event_id')"
                  :multiple="false"
                  :options="dropdowns.events"
                  @change="resetError('event_id')"
                  id="Event"
                  label="Select Event for Quotation"
                  textField="title"
                  type="text"
                  v-model="event"
                  valueField="id"
                />
              </PFormLayoutGroup>
              <!--                            <PFormLayoutGroup v-if="formFields.status !== 1">-->
              <!--                                <PMultiSelect-->
              <!--                                    :multiple="false"-->
              <!--                                    :options="dropdowns.status"-->
              <!--                                    id="Status"-->
              <!--                                    label="Select Status for Quotation"-->
              <!--                                    textField="title"-->
              <!--                                    type="text"-->
              <!--                                    v-model="status"-->
              <!--                                    valueField="id"-->
              <!--                                />-->
              <!--                            </PFormLayoutGroup>-->
              <!--                            <PStack v-if="formFields.status !== 1">-->
              <!--                                <PStackItem fill>-->
              <!--                                    <PButtonGroup>-->
              <!--                                        <PButton :disabled="global.pendingRequests > 0" @click="handleSubmitOperation()"-->
              <!--                                                 primary>-->
              <!--                                            <i class="fa fa-spin fa-spinner"-->
              <!--                                               v-show="global.pendingRequests > 0"></i>-->
              <!--                                            Update-->
              <!--                                        </PButton>-->
              <!--                                    </PButtonGroup>-->
              <!--                                </PStackItem>-->
              <!--                            </PStack>-->
            </PFormLayout>
          </PCard>
        </PLayoutAnnotatedSection>

        <PLayoutAnnotatedSection id="quotationEvents" title="Function Details">
          <div slot="description">
            <PTextStyle>Fill form for each function in the event.</PTextStyle>
            <br />
            <PTextStyle>Summary of the function is on right hand side.</PTextStyle>
            <br />
            <PTextStyle>Click Add More to Add new Function within the event.</PTextStyle>
            <br />
            <PTextStyle>Click Set Preferences to select Food, Decor and other details.
            </PTextStyle>
            <br />
          </div>
          <quotation-events
            @refresh="refreshData"
            :event="event"
            :lead="lead"
            :functions="formFields.quotationEvents"
            :book="true"
            :qid="formFields.id" :eid="formFields.event_id"
            v-if="formFields.id"></quotation-events>
        </PLayoutAnnotatedSection>
        <PLayoutAnnotatedSection id="quotationRooms" title="Room Details"
                                 v-if="dropdowns.rooms && dropdowns.rooms.length > 0">
          <div slot="description">
            <PTextStyle>Fill form to Add Rooms of each type.</PTextStyle>
            <br />
            <PTextStyle>Summary of each room type is on right hand side.</PTextStyle>
            <br />
            <PTextStyle>Click Add More to Add new room combination.</PTextStyle>
            <br />
            <PTextStyle>Don't forget to add remarks in case the guest has some special
              requests.
            </PTextStyle>
            <br />
          </div>
          <quotation-room :quotation_id="formFields.id" :rooms="formFields.quotationRooms"
                          v-if="formFields.id"></quotation-room>
        </PLayoutAnnotatedSection>
        <PLayoutAnnotatedSection id="quotationFinancial" title="Financial Detail">
          <div slot="description">
            <PTextStyle>Fill form for payment received</PTextStyle>
            <br />
            <PTextStyle>Click on print icon to print receipt</PTextStyle>
            <br />
            <PTextStyle>Click on share icon to share receipt with guest in email
            </PTextStyle>
            <br />
            <PTextStyle>You can also view Due Amount and set date for collection reminder
            </PTextStyle>
            <br />
          </div>
          <financial-detail
            :id="formFields.id"
            :total="parseFloat(totalEventAmount(formFields.quotationEvents).total+totalRoomAmount(formFields.quotationRooms).total)"
            v-if="formFields.id"></financial-detail>
        </PLayoutAnnotatedSection>
        <PLayoutAnnotatedSection title="Summary" v-if="formFields.id">
          <PCard sectioned>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th style="width: 10%;">GST Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="`$event-${index}`"
                      v-for="(event,index) in formFields.quotationEvents">
                    <td :rowspan="formFields.quotationEvents.length"
                        v-if="index == 0">
                      <strong>Function Details</strong>
                    </td>
                    <td>
                      <div>
                        <p>
                          {{ $global.dateFormat(event.from_date) }}</p>
                        <p>
                          {{ $global.timeFormat(event.from_time) }} -
                          {{ $global.timeFormat(event.to_time) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p @click="selectedEventId(event.id)"
                           v-b-modal="`summary-event`"><i
                          class="fa fa-plus fa-sm-square mr-2"></i>
                          {{ event ? event.event_type : "" }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        {{
                          $global.numberToLocationString(totalAmountS(event.fnb).amount
                            +
                            ( totalAmountS({
                              'fnbExtras': event.fnbExtras,
                              'fnbExtrasDiscount': event.fnbExtrasDiscount
                            }).amount )
                            + ( totalAmountS({
                              'decors': event.decors,
                              'decorsDiscount': event.decorsDiscount
                            }).amount )
                            + ( totalAmountS({
                              'otherCharges': event.otherCharges,
                              'otherChargesDiscount': event.otherChargesDiscount
                            }).amount ))
                        }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{
                          $global.numberToLocationString(totalAmountS(event.fnb).gstAmount
                            +
                            ( totalAmountS({
                              'fnbExtras': event.fnbExtras,
                              'fnbExtrasDiscount': event.fnbExtrasDiscount
                            }).gstAmount )
                            + ( totalAmountS({
                              'decors': event.decors,
                              'decorsDiscount': event.decorsDiscount
                            }).gstAmount )
                            + ( totalAmountS({
                              'otherCharges': event.otherCharges,
                              'otherChargesDiscount': event.otherChargesDiscount
                            }).gstAmount ))
                        }}
                      </div>
                    </td>
                    <td>
                      {{
                        $global.numberToLocationString(totalAmountS(event.fnb).total
                          +
                          ( totalAmountS({
                            'fnbExtras': event.fnbExtras,
                            'fnbExtrasDiscount': event.fnbExtrasDiscount
                          }).total )
                          + ( totalAmountS({
                            'decors': event.decors,
                            'decorsDiscount': event.decorsDiscount
                          }).total )
                          + ( totalAmountS({
                            'otherCharges': event.otherCharges,
                            'otherChargesDiscount': event.otherChargesDiscount
                          }).total ))
                      }}
                    </td>
                  </tr>
                  <tr :key="qr"
                      v-for="(r,qr) in formFields.quotationRooms">
                    <td :rowspan="formFields.quotationRooms.length"
                        v-show="qr == 0">
                      <strong>Room Details</strong></td>
                    <td>
                      <div>
                        <p>
                          {{ $global.dateFormat(r.check_in_date) }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {{
                            r.allRooms ? r.allRooms.title
                              : ""
                          }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>
                          {{
                            $global.numberToLocationString(totalRoomAmount(r).amount)
                          }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p>{{
                            $global.numberToLocationString(totalRoomAmount(r).gstAmount)
                          }}</p>
                      </div>
                    </td>
                    <td>
                      <p>
                        {{
                          $global.numberToLocationString(totalRoomAmount(r).total)
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Sub Total</strong>
                    </td>
                    <td colspan="3">
                      <strong>
                        {{ $global.numberToLocationString(total_amount) }}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {{
                          $global.numberToLocationString(totalRoomAmount(formFields.quotationRooms).gstAmount
                            +
                            totalEventAmount(formFields.quotationEvents).gstAmount)
                        }}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {{
                          $global.numberToLocationString(totalRoomAmount(formFields.quotationRooms).total
                            +
                            totalEventAmount(formFields.quotationEvents).total)
                        }}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4"></th>
                    <th>
                      <strong>Discount</strong>
                      <treeselect
                        :options="[{id:'percentage',label:'Percentage'},{id:'fixed',label:'Fixed'}]"
                        type="text"
                        @input="handleDiscount"
                        v-model="formFields.discount_type"
                        v-numericOnly.keyup
                      ></treeselect>
                    </th>
                    <td>
                      <b-form-input
                        class="mt-6"
                        type="text"
                        @input="handleDiscount"
                        v-model="formFields.discount_amount"
                        v-numericOnly.keyup
                      ></b-form-input>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4"></th>
                    <th><strong>Discount Amount</strong></th>
                    <td>{{ $global.numberToLocationString(discount_amount) }}</td>
                  </tr>
                  <tr>
                    <th colspan="4"></th>
                    <th>
                      <strong>GST</strong>
                    </th>
                    <td>
                      <b-form-input
                        type="number"
                        v-model.number="formFields.gst"
                        v-numericOnly.keyup
                      ></b-form-input>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4"></th>
                    <th><strong>Total</strong></th>
                    <td>
                      {{
                        $global.numberToLocationString(total_amount - discount_amount + formFields.gst)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </PCard>

                              <PStack class="my-7" distribution="trailing">
                                  <PButton
                                      @click="handleSubmit"
                                      primary
                                      variant="success"
                                      :disabled="global.pendingRequests > 0"
                                  >
                                      Save
                                  </PButton>
                              </PStack>
        </PLayoutAnnotatedSection>
      </PLayout>
    </PPage>

    <b-modal :hide-footer="true" id="summary-event" size="lg" title="EventSummary">
      <div>
        <quotation-event-summary :event_id="EventId"></quotation-event-summary>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moduleMixin from "./module.mixin";
import { mapState } from "vuex";
import QuotationEvents from "../quotations/quotationEvents/index.vue";
import QuotationRoom from "../quotations/quotationRooms/index.vue";
import { bus } from "@/main";
import handleError from "@/util/handleError";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import financialDetail from "./financialDetails";
import QuotationEventSummary from "@/view/pages/quotations/quotationEvents/summary";

export default {
  mixins: [moduleMixin, handleError, totalAmountMixin],
  components: {
    QuotationEvents,
    QuotationRoom,
    financialDetail,
    QuotationEventSummary,
  },
  async mounted() {
    this.getRooms();
    this.getLeads();

    if ( this.$route.params && this.$route.params.id ) {
      await this.handleEditOperation(this.$route.params.id);
    }

    bus.$on('quotation-data-refresh', () => {
      if ( this.formFields.id ) {
        if ( this.$route.params && this.$route.params.id ) {
          this.handleEditOperation(this.$route.params.id);
        }
      }
    });
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
