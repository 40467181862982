<template>
  <section class="panel_section">
    <!-- Left Panel -->
    <div class="left_side_panel tab">
      <div v-for="(menu,key) in foodMenus" :key="key">
        <button :class="`tablinks ${active_tab === menu.id ? 'active':''}`" @click="active_tab = menu.id">
          {{ menu.name }} <span>{{menu.qty > 0 ? `(${menu.qty})`: ''}}</span></button>
      </div>
    </div>
    <!-- Left Panel -->

    <!-- Center Panel -->
    <div class="center_side_panel">
      <div class="tabcontent" v-for="(menu,key) in foodMenus" :key="key" :id="menu.id" v-show="active_tab === menu.id">
        <div class="tab_body" v-if="active_tab === menu.id">
          <div class="bg-white">
            <b-form-group
              label="Total Items"
              label-for="package_items"
            >
              <b-form-input
                id="package_items"
                type="number"
                class="w-25"
                v-model.number="package_items[key].qty"
                @change="handleSubmit"
              ></b-form-input>
            </b-form-group>
          </div>
          <hr />
          <ul>
            <li v-for="(sub, sub_key) in menu.children" :key="sub_key"
                :class="`b-nav-tab ${sub.children.length <= 0 ? 'child':''}`">
              <div v-if="sub.children.length > 0">
                <hr v-if="sub_key > 0"/>
                <span>{{ sub.name }}</span>
                <ul class="mt-2">
                  <li v-for="(child, child_key) in sub.children" :key="child_key" class="b-nav-tab child">
                    <button @click="selectFood(key, sub_key, child_key)"
                            data-tab="AfricanSlush" :class="`b-nav-tab ${child.selected ? 'active': ''}`">
                      {{ child.name }}
                    </button>
                  </li>
                  <li class="b-nav-tab child">
                    <button @click="addFood(menu, sub)">
                      + Add
                    </button>
                  </li>
                </ul>
                <hr/>
              </div>
              <button @click="selectFood(key, sub_key)" v-else href="javascript:void(0);" data-tab="AfricanSlush"
                      :class="`b-nav-tab ${sub.selected ? 'active': ''}`">{{ sub.name }}
              </button>
            </li>

            <li class="b-nav-tab child">
              <button @click="addFood(menu)">
                + Add
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Center Panel -->


    <!-- Right Panel -->
    <div class="tab_right_content">
      <div class="right_panel_header">
        <h5>Selected Items <span>({{selected_items_count}})</span></h5>
      </div>

      <!-- Right Panel Content-->
      <div>
        <div class="b-tab active">
          <div class="right_panel_content">
            <div class="content" v-for="(menu,key) in selected_items" :key="key" :id="menu.id">
              <h4>{{ menu.name }} ({{getItemCount(menu)}})</h4>
              <ul>
                <li v-for="(sub_item, sub_key) in menu.children" :key="sub_key">
                  <div v-if="sub_item.children.length > 0">
                    <h4>{{ sub_item.name }}</h4>
                    <ul>
                      <li v-for="(child_item, child_key) in sub_item.children" :key="child_key">
                        {{child_item.name}}
                      </li>
                    </ul>
                  </div>
                  <span v-else>{{ sub_item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Panel Content-->

      <!-- Right Panel Content-->

      <!-- Right Panel Content-->

    </div>

    <b-modal :id="`add-food-menu`"
             no-close-on-backdrop
             no-close-on-esc
             hide-footer
             scrollable size="xl"
             :title="`Add Food Menu - ${add_menu.parent ? add_menu.parent.name : ''}${add_menu.sub ? ` / ${add_menu.sub.name}` : ''}`">
      <b-container fluid>
        <form @submit.prevent="handleSubmitFoodMenu" autocomplete="off">
          <b-row>
            <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    :invalid-feedback="getError('name')"
                    label="Name *"
                    label-for="name"
                  >
                    <b-form-input
                      :state="hasError('category') ? true : null"
                      @focus="$event.target.select()"
                      id="name"
                      type="text"
                      v-model="add_menu.category"
                    ></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row><!--/b-row-->
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :invalid-feedback="getError('description')"
                    label="Description"
                    label-for="description"
                  >
                    <b-form-textarea
                      :state="hasError('description') ? true : null"
                      @focus="$event.target.select()"
                      id="description"
                      type="text"
                      v-model="add_menu.description"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row>
              <hr />
              <b-row class="operation-footer">
                <b-col sm="12">
                  <b-button
                    size="sm"
                    type="submit"
                    variant="primary"
                  >
                    <i class="fa fa-spin fa-spinner"
                       v-show="loading"></i>
                    <i class="fa fa-save fa-sm"></i>
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-modal>
    <!-- Right Panel -->
  </section>
</template>

<script>
import {request} from "@/core/services/Request";
import HandleError from "@/util/handleError";
import Error from "@/core/services/Error";


const ADD_MENU_FORM_STATE = {
  parent: null,
  sub: null,
  category: null,
  parent_category_id: null,
  description: null
};


export default {
  name: "FoodMenu",
  props: ["event_id", "package_id"],
  mixins: [HandleError],
  data() {
    return {
      foodMenus: [],
      loading: false,
      selectedFoodMenus: [],
      active_tab: 0,
      selected_items_count: 0,
      selected_items: [],
      add_menu: { ...ADD_MENU_FORM_STATE },
      package_items: []
    }
  },
  async created() {
    await this.getFoodMenus();
    this.getSelectedItems()
  },
  methods: {
    async selectFood(key, sub_key, child_key) {
      if (child_key !== undefined) {
        this.foodMenus[key].children[sub_key].children[child_key].selected = !this.foodMenus[key].children[sub_key].children[child_key].selected
        const child_count = this.foodMenus[key].children[sub_key].children.filter((item) => item.selected).length;
        this.foodMenus[key].children[sub_key].selected = child_count > 0
      } else this.foodMenus[key].children[sub_key].selected = !this.foodMenus[key].children[sub_key].selected;

      this.getSelectedItems()

      await this.handleSubmit();

    },
    addFood(parent, sub = null) {

      this.add_menu.parent = parent;
      this.add_menu.sub = sub;

      this.$bvModal.show('add-food-menu');

    },
    async handleSubmitFoodMenu() {

      try {
        this.loading = true;
        this.add_menu.parent_category_id = this.add_menu.sub ? this.add_menu.sub.id : this.add_menu.parent.id;

        const response = await request({
          url: 'fnb/food/menus/create',
          method: 'post',
          data: this.add_menu,
        });

        const active = this.active_tab;

        await this.getFoodMenus();

        this.add_menu = { ...ADD_MENU_FORM_STATE };

        this.active_tab = active;
        this.$global.itemAdded();
        this.loading = false;

        this.$bvModal.hide('add-food-menu');

      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    getItemCount(menu){

      let count = 0;
      menu.children.map(item => {
        if (item.children.length > 0) {
          count += item.children.length
        } else count++
      })

      return count
    },
    getSelectedItems() {

      let list = [];

      this.selected_items_count = 0;
      let foodMenus = _.cloneDeep(this.foodMenus);

      this.selected_items = foodMenus.filter((menu, index) => {
        const selected = menu.children.filter((item) => item.selected)

        if (selected.length > 0) {

          let selected_items = selected.filter((sub) => sub.selected);
          selected_items = selected_items.map((item) => {

            if (item.children.length > 0) {

              item.children = item.children.filter((child) => child.selected)
              this.selected_items_count += item.children.length
              return item
            }

            this.selected_items_count += 1

            return item
          })
          foodMenus[index].children = selected_items
          return true;
        }

        return false;
      })
    },
    async getFoodMenus() {
      try {
        const response = await request({
          url: `/dropdowns/package/food/menus`,
          method: 'post',
          data: {
            quotation_event_id: this.event_id,
            package_id: this.package_id,
          }
        });

        const {data} = response;

        this.foodMenus = data.menu_items;
        this.active_tab = this.foodMenus[0]?.id

        console.log('len',data.package_items.length)
        if (data.package_items.length > 0) {
          this.package_items = data.package_items;
        } else {

          this.package_items = [];
          this.foodMenus.map((item) => {
            if (item.is_parent && item.qty !== null) {
              console.log({item})
              this.package_items.push({
                id: item.id,
                qty: item.qty,
                order: item.order,
                name: item.name,
              });
            }
          })
        }


      } catch (e) {
        console.error(e)
      } finally {
      }
    },
    async handleSubmit() {

      const list = [];

      this.selected_items.map(parent => {
        parent.children.map(sub => {

          if (sub.has_child) {
            sub.children.map(child => {
              list.push({
                quotation_event_id: this.event_id,
                food_menu_id: child.id,
                food_menu_label: child.name,
                parent_food_id: child.parent.id,
                parent_food_label: child.parent.name,
                sub_food_id: child.sub.id,
                sub_food_label: child.sub.name,
                description: child.description
              })
            })
          } else {
            list.push({
              quotation_event_id: this.event_id,
              food_menu_id: sub.id,
              food_menu_label: sub.name,
              parent_food_id: sub.parent.id,
              parent_food_label: sub.parent.name,
              description: sub.description
            })
          }
        })
      })


      try {
        const response = await request({
          url: `/quotations/events/fnb/update/food-menu/${this.event_id}`,
          method: 'post',
          data: {
            food_menus: list,
            package_items: this.package_items
          }
        });

        // const {data} = response;
        // this.$global.notify(data.message);

      } catch (e) {
        console.error(e)
      } finally {
      }
    }
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
</script>

<style scoped>
/* Left Panel CSS */
.left_side_panel {
  float: left;
  background-color: #f1f1f1;
  width: 16%;
  height: 37.6rem;
  overflow-y: scroll;
}

.left_side_panel button {
  display: block;
  background-color: inherit;
  color: #000;
  background: #fff;
  padding: 15px 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  outline: none;
  text-align: left;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.left_side_panel button > span {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
}

.left_side_panel button:hover {
  background-color: #01003D;
  color: #fff;
}

.left_side_panel button.active {
  background-color: #01003D;
  color: #fff;
}


/* Center Panel CSS */
.center_side_panel .tab_body {
  height: 37.6rem;
  overflow-y: scroll;
  background: #fff;
}

.center_side_panel .tabcontent {
  float: left;
  padding: 0;
  width: 55%;
  border-left: none;
  margin-left: 20px;
}

.center_side_panel .tab_body ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
}

.center_side_panel .tab_body ul li.child {
  display: inline-block;
}

.center_side_panel .tab_body ul li button {
  align-items: center;
  justify-content: center;
  background: #fff;
  display: inline-flex;
  width: 151px;
  position: relative;
  height: 56px;
  text-align: center;
  padding: 10px;
  margin: 10px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  vertical-align: middle;
  border-radius: 5px;
  color: #01003D;
  border: 2px solid #0000;
}


.center_side_panel .tab_body ul li button:hover {
  text-decoration: none;
  border: 2px solid #01003D;
  background: #E2EDFF;
}

.center_side_panel .tab_body ul li button.active {
  text-decoration: none;
  border: 2px solid #01003D;
  background: #b9ccea;
}


.center_side_panel .tab_body ul li button {
  border: 2px solid #9d9da2;
}


/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #A9A9A9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #A9A9A9;
}


/* Right Panel CSS */
.tab_right_content {
  float: left;
  background-color: #f1f1f1;
  width: 27%;
}

.tab_right_content .b-tab {
  display: none;
}

.tab_right_content .b-tab.active {
  display: block;
}

.tab_right_content .right_panel_header {
  border-bottom: 1px solid #f1f1f1;
  background: #fff;
}

.tab_right_content .right_panel_header h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #787878;
  letter-spacing: 0.01em;
  padding: 8px 10px;
  margin: 0;
}

.tab_right_content .right_panel_header h5 span {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.tab_right_content .right_panel_content {
  padding: 12px;
  height: 33.5rem;
  overflow-y: scroll;
  background: #fff;
}

.tab_right_content .right_panel_content .content {
  margin-bottom: 16px;
  padding: 0;
}

.tab_right_content .right_panel_content .content h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  margin: 10px 0;
}

.tab_right_content .right_panel_content .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

.tab_right_content .right_panel_content .content ul li {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: #676767;
}

.tab_right_content .right_panel_footer {
  padding: 16px 8px;
  border-top: #f1f1f1 solid;
  background: #fff;
}

.tab_right_content .right_panel_footer textarea.form-control {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #616161;
  border: 1px solid #C5C5C5;
}

.tab_right_content .right_panel_footer button.submit_btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  background: #187DE4;
  border: 0;
  padding: 8px 15px;
  color: #fff;
  border-radius: 3px;
  float: right;
  margin-top: 5px;
}
</style>
