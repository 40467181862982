<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <b-container class="set-fnb-container" fluid>
        <b-row>
          <b-col class="p-0 mt-5" cols="12" md="12" lg="12" sm="12">
            <form @submit.prevent="handleSubmitOperation" autocomplete="off">
              <b-row>
                <div class="table-responsive">
                  <table
                    class="v-data-table table table-head-custom table-vertical-center table-responsive table-responsive theme--light">
                    <thead>
                    <tr>
                      <th width="50%">Select Package</th>
                      <th>Rate</th>
                      <th>No Of Pax</th>
                      <th>Expected Pax</th>
                      <th width="15%" class="text-right">Sub Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td width="50%">
                        <b-form-select
                          id="package_id"
                          :options="dropdowns.packageType"
                          v-model="formFields.package_id"
                          :class="[{'invalid is-invalid': (formErrors.has('package_id'))}]"
                          @change="handlePackageFoodMenu()"
                        ></b-form-select>
                      </td>
                      <td>
                        <b-form-input
                          :disabled="!formFields.package_id"
                          id="rate"
                          v-model="formFields.rate"
                          type="text"
                          @keyup="handleChangeInput"
                          v-numericOnly.keyup
                          :state="((formErrors.has('rate') ? false : null))"
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          :disabled="!formFields.package_id"
                          id="no_of_pax"
                          v-model="formFields.no_of_pax"
                          type="text"
                          @keyup="handleChangeInput"
                          v-numericOnly.keyup
                          :state="((formErrors.has('no_of_pax') ? false : null))"
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          :disabled="!formFields.package_id"
                          id="no_of_pax"
                          v-model="formFields.expected_pax"
                          type="text"
                          @keyup="handleChangeInput"
                          v-numericOnly.keyup
                          :state="((formErrors.has('expected_pax') ? false : null))"
                        ></b-form-input>
                      </td>
                      <td class="text-right">
                        {{ $global.numberToLocationString(totalAmount(formFields).amount) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-row>
              <b-row>
                <b-col class="pl-3" cols="8">
                  <b-form-group
                    label="Note"
                    label-for="note"
                    class="mb-0"
                  >
                    <b-form-textarea
                      id="textarea-formatter"
                      v-model="formFields.note"
                      placeholder="Food package note"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col class="p-0" cols="4" md="4" lg="4" sm="6">
                  <b-card>
                    <b-card-text>
                      <table id="discount-table" class="table table-bordered m-0">
                        <tr>
                          <th>Total Amount</th>
                          <td>
                            {{
                              $global.numberToLocationString(totalAmountS(formFields).amount)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>GST@{{ totalAmountS(formFields).gst }}%
                          </th>
                          <td>
                            {{
                              $global.numberToLocationString(totalAmountS(formFields).gstAmount)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>Total Amount + GST</th>
                          <td>
                            {{
                              $global.numberToLocationString(totalAmountS(formFields).total)
                            }}
                          </td>
                        </tr>
                      </table>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getPropertyId} from "@/util/globalFunction";
import handleMatchDataMixin from "../handleMatchDataMixing";
import {bus} from "../../../../../../main";
import totalAmountMixin from "../../../../../../assets/js/components/totalAmountMixin";


const DEFAULT_FORM_STATE = {
  package_id: null,
  rate: 0,
  note: 0,
  expected_pax: 0,
  no_of_pax: 0,
  sub_total: 0,
  s_gst: null,
  gst: null,
  total: null,
  discount: null,
  discount_type: null,
};

export default {
  mixins: [handleMatchDataMixin, totalAmountMixin],
  props: ["id", "quotation_id", "event_id", "fnb", "nkey", 'book'],
  data() {
    return {
      formFields: {...DEFAULT_FORM_STATE},
      formErrors: new Error({}),
      tab_open: false,
      dropdowns: {
        packageType: [],
        discountType: [{id: 'percentage', label: 'Percentage'}, {id: 'fixed', label: 'Fixed'}],
      },
      mainIndex: [],
      loading: true,
      isEditDatSave: false,
    };
  },
  async mounted() {
    this.formFields.package_id = this.formFields.id = null;
    await this.handleEditOperation(this.event_id);

    this.$forceUpdate();
  },
  methods: {
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/quotations/events/fnb/detail/${id}`,
        });
        const {data} = response.data;
        this.loading = false;

        await this.getPackages(data.id);
        this.formFields.package_id = data.package_id;

        this.$nextTick(function () {

          if (data.package_id) {
            this.formFields = {
              ...data,
              sub_total: data.rate * data.no_of_pax,
            };
          }
          this.oldData = _.cloneDeep(this.formFields);
        });

      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = {...DEFAULT_FORM_STATE};
      }
    },
    async handleSubmitOperation() {

      this.formErrors = new Error({});
      try {

        this.formFields.descriptions = this.descriptions;
        const response = await request({
          url: this.formFields.id ? '/quotations/events/fnb/update' : '/quotations/events/fnb/create',
          method: 'post',
          data: {
            ...this.formFields,
            quotation_id: this.quotation_id, quotation_event_id: this.event_id
          },
        });
        if (this.formFields.id) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
        }
        this.$global.handleServerError(error);
      }

      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },
    async handlePackageFoodMenu() {
      if (this.formFields.package_id) {
        try {
          const response = await request({
            method: 'get',
            url: `/fnb/packages/detail/${this.formFields.package_id}`,
          });
          const {data} = response.data;
          this.formFields.rate = data.rate;
          this.formFields.gst = parseFloat(data.s_gst) + parseFloat(data.c_gst);
          this.formFields.expected_pax = 0;
          this.formFields.no_of_pax = 0;
          this.formFields.sub_total = 0;
          this.formFields.total = (((this.formFields.gst * this.formFields.sub_total) / 100) + this.formFields.sub_total);
          this.oldData = _.cloneDeep(this.formFields);
          this.handleChangeInput();
        } catch (e) {
          this.$global.itemEditFails();
          this.formFields = {...DEFAULT_FORM_STATE};
        }
      }
    },
    async getPackages(fnbId = null) {
      try {
        const response = await request({
          url: `/dropdowns/packages`,
          method: 'post',
          data: {
            quotation_event_id: fnbId
          }
        });

        const {data} = response.data;
        this.dropdowns.packageType = _.map(data, (i) => {
          return {
            value: i.id,
            text: i.title
          };
        });

      } catch (e) {
      }
    },
    handleDiscount() {
      const mainTotal = this.totalAmountS(this.formFields);

      if ((this.formFields.discount_type == 'percentage' && this.formFields.discount > 100)) {
        this.formFields.discount = 100;
      } else if (this.formFields.discount_type == 'fixed' && this.formFields.discount > mainTotal.amount) {
        this.formFields.discount = this.formFields.rate * this.formFields.no_of_pax;
      }

      this.handleChangeInput();
    },
    handleChangeInput() {
      if (!this.formFields.discount_type) {
        this.formFields.discount = 0;
      }

      this.$emit('update', 'fnb', !_.isEqual(this.oldData, this.formFields));

      if (this.totalAmountS(this.formFields).amount < 0) {
        this.formFields.discount = 0;
      }


      bus.$emit('fnb', {...this.formFields}, this.nkey);

      this.isEditDatSave = true;
    }
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
