import {bus} from "../../../../../main";

export default {
    data() {
        return {
            oldData: [],
        }
    },
    created() {
        bus.$off("quotation-fnb-pending-data-save")
        bus.$off("quotation-fnb-extra-pending-data-save")
        bus.$off("quotation-decors-pending-data-save")
        bus.$off("quotation-other-charges-pending-data-save")
    },
    methods: {
        addHandler(data) {
            if (_.isEqual(this.oldData, data)) {
                this.$store.dispatch("removeDataMatchRequests")
            } else {
                this.$store.dispatch("addDatMatchRequests")
            }
        },
        removeHandler() {
            this.$store.dispatch("removeDataMatchRequests")
        }
    },
}
