<template>
    <div class="">
        <div class="Polaris-Labelled__LabelWrapper">
            <div class="Polaris-Label">
                <label :id="`${id}Label`" :for="id" class="Polaris-Label__Text">
                    {{ label }}
                </label>
            </div>
        </div>
        <div :class="className">
            <treeselect
                v-model="computedValue"
                :options="options"
                @input="$emit('input',$event)"
                :disabled="disabled"
                :multiple="multiple"
                v-bind="[$attrs,$props]"
            />
        </div>
        <div class="Polaris-Labelled__Error" v-if="error">
            <div id="PolarisTextField6Error" class="Polaris-InlineError">
                <div class="Polaris-InlineError__Icon">
                    <PIcon source="AlertMinor" />
                </div>{{error}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PSelect",
    data() {
      return {
          selected: this.value
      }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        options: {
            type: [Object,Array],
            default: function () {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        value: {
            type: [Object, Array, String, Number]
        },
        multiple: {
            type: Boolean,
            default: false
        },
        label: String,
        error: String
    },
    watch: {
        value: {
            handler: function (data) {
                this.selected = data
            },
            immediate: true
        }
    },
    computed: {
        computedValue:{
            get() {
                return this.selected;
            },
            set(value) {
                this.selected = value;
                this.$emit('change', value);
            }
        },
        className() {
            return ['Polaris-Select', this.disabled && 'Polaris-Select--disabled', this.error && 'invalid'].filter(Boolean).join(' ');
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
.Polaris-Select {
    &.invalid {
        .vue-treeselect__control {
            border-color: #bf0711;
            background-color: #fbeae5 !important;
        }
    }
}
</style>
