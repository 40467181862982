<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container fluid>
      <form @submit.prevent="handleSubmitOperation" autocomplete="off">
        <b-row>
          <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
            <b-row>
              <div class="table-responsive mt-5">
                <span
                  class="text-danger">{{
                    ( formErrors.has('fnbExtras') ) ? "Please select one record." : ""
                  }}</span>
                <table
                  class="v-data-table table table-head-custom table-vertical-center table-responsive table-responsive theme--light"
                  width="100%">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Rate</th>
                      <th>No of pax</th>
                      <th class="text-right">Sub Total</th>
                      <th class="text-right">GST%</th>
                      <th class="text-right">GST Amount</th>
                      <th class="text-right">Total</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(extra,index) in formFields.fnbExtras.length">
                      <td width="20%">
                        <b-form-select
                          value-field="id"
                          text-field="label"
                          @change="handleSelectFnbExtra(index)"
                          :options="_.map(dropdowns.extras,(i) =>{
                                                        return{id:i.id,label:i.title}})"
                          v-model="formFields.fnbExtras[index].fnb_extras_id"
                          :class="((formErrors.all()[`fnbExtras.${index}.fnb_extras_id`] ?  'error-input'  : null))"
                        >
                        </b-form-select>
                      </td>
                      <td>
                        <b-form-input
                          :class="((formErrors.all()[`fnbExtras.${index}.rate`] ?  'error-input'  : null))"
                          @keyup="handleChangeInput"
                          v-model="formFields.fnbExtras[index].rate"
                          v-numericOnly.keyup
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          :class="((formErrors.all()[`fnbExtras.${index}.no_of_pax`] ?  'error-input'  : null))"
                          @keyup="handleChangeInput"
                          v-model="formFields.fnbExtras[index].no_of_pax"
                          v-numericOnly.keyup></b-form-input>
                      </td>
                      <td class="text-right" width="10%">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.fnbExtras[index]).amount)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.fnbExtras[index]).gst)
                        }}
                      </td>
                      <td class="text-right" width="15%">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.fnbExtras[index]).gstAmount)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $global.numberToLocationString(totalAmountS(formFields.fnbExtras[index]).total)
                        }}
                      </td>
                      <td class="text-center">
                        <i class="fa fa-trash mr-2" @click="removeFnbExtra(index)"></i>
                      </td>
                    </tr>
                  </tbody>
                  <b-col sm="12" class="mb-5">
                    <b-button variant="primary" @click="addMore">Add More</b-button>
                  </b-col>
                </table>
              </div>
            </b-row>
          </b-col><!--/b-col-->
        </b-row><!--/b-row-->
        <b-row>
          <b-col class="p-0" cols="4" offset="8">
            <b-card>
              <b-card-text>
                <table class="table table-bordered" id="discount-table">
                  <tr>
                    <th>Total Amount</th>
                    <td>
                      {{
                        $global.numberToLocationString(
                          totalAmountS({
                            'fnbExtras': formFields.fnbExtras,
                            'discount_type': formFields.discount_type, 'discount':
                            formFields.discount
                          }).amount)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      GST@{{
                        $global.numberToLocationString(
                          totalAmountS({
                              'fnbExtras': formFields.fnbExtras
                            },
                            {
                              'discount_type': formFields.discount_type, 'discount':
                              formFields.discount
                            }).gst)
                      }}%
                    </th>
                    <td>
                      {{
                        $global.numberToLocationString(
                          totalAmountS({
                            'fnbExtras': formFields.fnbExtras,
                            'discount_type': formFields.discount_type, 'discount':
                            formFields.discount
                          }).gstAmount)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Total Amount + GST</th>
                    <td>
                      {{
                        $global.numberToLocationString(totalAmountS(
                          {
                            'fnbExtras': formFields.fnbExtras,
                            'discount_type': formFields.discount_type, 'discount':
                            formFields.discount
                          }).total)
                      }}
                    </td>
                  </tr>
                </table>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </form><!--/form-->
    </b-container><!--/b-container-->
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getPropertyId } from "@/util/globalFunction";
import handleMatchDataMixin from "../handleMatchDataMixing";
import { bus } from "../../../../../../main";
import totalAmountMixin from "../../../../../../assets/js/components/totalAmountMixin";

const DEFAULT_FORM_STATE = {
  fnbExtras: [],
  discount_type: null,
  discount: null,
};

const SINGLE_DATA = {
  type: null,
  rate: 0,
  id: null,
  no_of_pax: 0,
  gst: 0,
  gstAmount: 0,
  sub_total: 0,
  total: 0,

};


export default {
  mixins: [handleMatchDataMixin, totalAmountMixin],
  props: ["event_id", "nkey"],
  data() {
    return {
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      dropdowns: {
        extras: [],
      },
      loading: true,
      fnb_extra: { ...SINGLE_DATA },
      oldData: [],
    };
  },
  methods: {
    handleDiscount() {
      let mainTotal = this.totalAmountS({ 'fnbExtras': this.formFields.fnbExtras }).amount;
      if ( ( this.formFields.discount_type == 'percentage' && this.formFields.discount > 100 ) ) {
        this.formFields.discount = 100;
      } else if ( this.formFields.discount_type == 'fixed' && this.formFields.discount >= mainTotal ) {
        this.formFields.discount = mainTotal;
      }
      this.handleChangeInput();
    },
    removeFnbExtra(index) {
      this.$delete(this.formFields.fnbExtras, index);
      if ( !this.formFields.fnbExtras.length ) {
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
      this.handleDiscount();
      this.handleChangeInput();
    },
    async handleSelectFnbExtra(index) {
      this.formErrors = new Error({});

      const data = this.formFields.fnbExtras[index];
      const extrasIndex = _.findIndex(this.dropdowns.extras, (i) => i.id == data.fnb_extras_id);

      if ( extrasIndex !== -1 ) {
        const fnbExtras = this.dropdowns.extras[extrasIndex];

        this.formFields.fnbExtras[index].gst = parseFloat(fnbExtras.s_gst) + parseFloat(fnbExtras.c_gst);
        this.formFields.fnbExtras[index].rate = fnbExtras.rate;
        this.formFields.fnbExtras[index].no_of_pax = 0;

        const changeTotal = this.totalAmountS(
          {
            'fnbExtras': [this.formFields.fnbExtras[index]],
            'discount_type': this.formFields.discount_type, 'discount':
            this.formFields.discount
          });


        this.formFields.fnbExtras[index].sub_total = this.$global.numberToLocationString(changeTotal.amount);
        this.formFields.fnbExtras[index].gstAmount = this.$global.numberToLocationString(changeTotal.gstAmount);

        this.handleChangeInput();
      }
    },
    addMore() {
      this.formFields.fnbExtras.push({
        fnb_extras_id: null,
        gst: null,
        rate: null,
        label: null,
        no_of_pax: null
      });
    },

    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/quotations/events/extras/detail/${ id }`,
        });
        const { data } = response.data;
        this.loading = false;
        if ( data ) {
          let self = this;
          _.map(data.fnbExtras, (item, index) => {
            self.formFields.fnbExtras.push({
              fnb_extras_id: item.fnb_extras_id,
              gst: item.gst,
              rate: item.rate,
              no_of_pax: item.no_of_pax
            });
          });

          this.formFields.discount_type = data.discount_type;
          this.formFields.discount = data.discount;

          this.oldData = _.cloneDeep(this.formFields);

        }
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: '/quotations/events/extras/create',
          method: 'post',
          data: {
            fnbExtras: this.formFields.fnbExtras,
            quotation_event_id: this.event_id,
            discount: this.formFields.discount,
            discount_type: this.formFields.discount_type,
          },
        });
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }
    },
    async getExtras() {
      this.formFields.fnbExtras = [];
      try {
        const response = await request({
          url: `/dropdowns/extras`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.extras = data;
      } catch (e) {

      }
    },
    handleChangeInput() {
      if ( !this.formFields.discount_type ) {
        this.formFields.discount = 0;
      }

      if ( this.totalAmountS(this.formFields).amount < 0 ) {
        this.formFields.discount = 0;
      }

      this.$emit('update', 'fnb_extras', !_.isEqual(this.oldData, this.formFields));

      bus.$emit('fnb-extras', [...this.formFields.fnbExtras], {
        'discount_type': this.formFields.discount_type,
        'discount': this.formFields.discount
      }, this.nkey);
    },
  },
  async mounted() {
    this.formFields.fnbExtras = [];
    this.formErrors = new Error({});
    await this.getExtras();
    await this.handleEditOperation(this.event_id);
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
