<template>
    <div>
        <b-container fluid>
            <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                <b-row>
                    <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                        <b-row>
                            <b-col sm="12" class="mt-2">
                                <b-form-group
                                    :invalid-feedback="formErrors.first('note')"
                                >
                                    <ckeditor
                                        :config="editorDefaultConfig"
                                        v-model="formFields.note">
                                    </ckeditor>
                                    <b-form-invalid-feedback
                                        :state="(!(formErrors.has('note')))">
                                        {{ formErrors.first('note') }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col><!--/b-col-->
                        </b-row>
                    </b-col><!--/b-col-->
                </b-row><!--/b-row-->
            </form><!--/form-->
        </b-container><!--/b-container-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { bus } from "../../../../../../main";

const DEFAULT_FORM_STATE = {
    note: null,
};

export default {
    props: ["onote", "event_id"],
    data() {
        return {
            formFields: { ...DEFAULT_FORM_STATE },
            formErrors: new Error({}),
            editorDefaultConfig: {
                extraPlugins: ['colorbutton,justify'],
                toolbarGroups: [
                    { name: "document", groups: ["mode", "document", "doctools"] },
                    { name: "clipboard", groups: ["clipboard", "undo"] },
                    {
                        name: "editing",
                        groups: ["find", "selection", "spellchecker", "editing"]
                    },
                    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                    {
                        name: "paragraph",
                        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
                    },
                    { name: "links", groups: ["links"] },
                    { name: "styles", groups: ["styles"] },
                    { name: 'colors' },
                    { name: "tools", groups: ["tools"] },
                    { name: "others", groups: ["others"] },
                ]
            }
        };
    },
    methods: {
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/quotations/events/office/notes/detail/${ id }`,
                });
                const { data } = response.data;
                if ( data ) {
                    this.formFields = {
                        ...data,
                    };
                }
            } catch (e) {
                this.$global.itemEditFails();
                this.formFields = { ...DEFAULT_FORM_STATE };
            }
        },
        async handleSubmitOperation() {
            this.formErrors = new Error({});
            try {
                const response = await request({
                    url: this.formFields.id ? '/quotations/events/office/notes/update' : '/quotations/events/office/notes/create',
                    method: 'post',
                    data: { ...this.formFields, quotation_event_id: this.event_id },
                });
                if ( this.formFields.id ) {
                    this.$global.itemUpdated();
                } else {
                    this.$global.itemAdded();
                }
                this.handler = null;

                // bus.$emit('quotation-event-refresh');

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
                this.$global.handleServerError(error);
            }
        }
    },
    mounted() {
        if ( this.event_id ) {
            this.handleEditOperation(this.event_id);
        }
    },
    beforeDestroy() {
        bus.$off('quotation-event-refresh');
    },
    computed: {
        ...mapState([
            'global',
        ]),
    },
};
</script>
